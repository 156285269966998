import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { mapStyle } from "../../utils/mapUtils";
import Pin from "../../assets/icons/pin-blu.svg";
import ActivePin from "../../assets/icons/pin-verde.svg";
import {
  PinDefault,
  PinSelected,
  PinOriginDefault,
  PinOriginSelected,
} from "../../assets/Pin";

const PudosMap = (props) => {
  const originalLatitude = props.shipping?.deliveryAddress?.latitude;
  const originalLongitude = props.shipping?.deliveryAddress?.longitude;
  const [centerLatitude, setCenterLatitude] = useState(originalLatitude);
  const [centerLongitude, setCenterLongitude] = useState(originalLongitude);
  useEffect(() => {
    if (!!props.selectedPudo) {
      setCenterLatitude(props.selectedPudo.latitude);
      setCenterLongitude(props.selectedPudo.longitude);
    }
  }, [props.selectedPudo]);

  const containerStyle = {
    width: props.width,
    // height: "488px",
    height: "70vh",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD60SmXreBL_59HmY5l6sYDnFG0zbe-Zkg",
  });

  const [map, setMap] = React.useState(null);
  // const onLoad = React.useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   //     const bounds = new window.google.maps.LatLngBounds(center);
  //   //     map.fitBounds(bounds);
  //   //     setMap(map);
  // }, []);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: centerLatitude, lng: centerLongitude }}
      zoom={13}
      // onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        styles: mapStyle,
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}

      {props.pudos.map((pudo) => {
        const isSelected = props.selectedPudo?.id === pudo.id;

        if (isSelected) {
          return (
            <Marker
              position={{
                lat: pudo.latitude,
                lng: pudo.longitude,
              }}
              icon={{
                url: PinSelected,
                scaledSize: new window.google.maps.Size(60, 60),
              }}
              zIndex={10000}
            />
          );
        } else {
          return (
            <Marker
              onClick={() => props.setSelectedPudo(pudo)}
              position={{
                lat: pudo.latitude,
                lng: pudo.longitude,
              }}
              icon={{
                url: PinDefault,
                scaledSize: new window.google.maps.Size(60, 60),
              }}
            />
          );
        }
      })}

      <Marker
        position={{
          lat: originalLatitude,
          lng: originalLongitude,
        }}
        icon={{
          url: PinOriginSelected,
          scaledSize: new window.google.maps.Size(45, 45),
        }}
        zIndex={10000}
      />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(PudosMap);
