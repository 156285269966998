import * as React from "react";

const Logo = (props) => (
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 555.4 218.5"
    style={{
      enableBackground: "new 0 0 555.4 218.5",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M45.7 145.2c0 12.7 11.2 25.9 26.3 25.9h86.3c8.3 0 10.2-7.3 10.2-7.3l4-13.4 8-31.5H74.9c-23.9-.1-29.2 18-29.2 26.3zm28.3-5.9h82l-4.4 12.2H74.9c-9.2 0-8.3-12.2-.9-12.2z" />
    <path d="M381.7 192.7h-162c-4.6 0-8.2-4-7.8-8.5C214 161 205 98.3 331.8 98.3c29.3 2.9 41-41.6 3.9-51.9-2.1-.5-41.1-9-74.4 4.6-11.7 4.7-4.9 22.3 6.9 17.6 30.4-10.7 72.4-7.3 71.4 6.4-.1 1.1-.5 4.5-6.6 4.5-49.8-.1-84.1 7.7-106 24.2 9.5-38.2-6.5-88.5-50.1-97-3.9-1-9.3-1.5-17.1-1.5H69.9c-13.2.4-13.4 18.8-.3 19.5 10.1.5 99.3-1.1 106.8 1 25.8 7.8 38.4 42.3 31.9 67.6l-21.7 91s-2 7.8-11.7 7.8H69c-22 0-44.9-21.5-44.9-47.8 0-17.1 15.1-46.3 46.3-46.3h115.4l4.1-18.5s6.3-22-13.7-31.2c-4.9-2-11.2-2-19-2H59.6c-5.1 0-9.5 3.7-10 8.8-.6 5.8 4 10.7 9.7 10.7 3.3.5 107.2-1.1 106.8 1 3.9 2.4 4.4 8.3 2.4 12.7h-101C33.8 79 3.1 109.3 3.1 143.9c0 48.3 41.5 67.8 64.9 67.8h314c12.5-.3 12.2-19.1-.3-19z" />
    <path d="M245.7 36.9c-4.6 2.8-10.6 1.2-13.2-3.6-2.4-4.5-.6-10.1 3.8-12.8C275.5-2.9 372.1-3 392.8 37.5l2.5-24c.6-5.2 5.2-8.9 10.4-8.3 5.2.6 8.9 5.3 8.3 10.4l-14.7 131.9s-8.2 46.5 55.2 46.5 65.9-48.6 65.9-48.6l13-131.8c.5-5.2 5.1-9 10.3-8.4 5.2.5 9 5.1 8.4 10.3l-13.2 133.7s.5 64.6-85.4 64.6-73-66.8-73-66.8l3-26.7s-13.4 14.6-62 15.2c-24.3.3-36.6 2.9-45.5 12.8-1.2 1.4-.2 3.6 1.6 3.6l80.6.2c12.1-.1 12.5 18.8 0 18.9l-105.7-.3c-6 0-9.3-4.9-9.3-10.8 0-17.9 20-44.2 76.3-44.2 38.1 0 65.6-11 65.7-44 0 0-1-33.3-48-44.6-50.4-10-91.5 9.8-91.5 9.8z" />
    <path d="M457.6 170.3c37.9 0 38-39.3 38-39.3l11.5-115.4c.5-5.2-3.2-9.8-8.4-10.3s-9.8 3.2-10.3 8.4l-11.5 115.5c0 .1-.3 22.3-19.5 22.3-14.3 0-10.7-18.8-10.7-18.9l12.8-116.9c.6-5.2-3.2-9.8-8.3-10.4-5.2-.6-9.8 3.2-10.4 8.3L428 130.3c-.1-.1-8.3 40 29.6 40z" />
  </svg>
);

export default Logo;
