import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Text, translate } from "../../assets/lemmata";
import { Card } from "../../components/Cards/Cards";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection from "../../components/SideSection/SideSection";
import { addReturn, getParcels } from "../../reducers/returns";
import { getShippingById } from "../../reducers/shippings";
import { Button, BackBtn } from "../../shared/Buttons";
// import { Checkbox } from "../../shared/Checkboxes";
import { Input } from "../../shared/Inputs";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import Calendar from "../../components/Calendar/Calendar";
import zipcodes from "../../db/ZipCodes.json";
import { SelectPage as SelectZipCodes } from "../../shared/selectZipcodes";
import moment from "moment";
import { Popup } from "../../components/Popups/Popup";

const getZipCodes = (country) => {
  const zipByCountry = zipcodes.find((zip) => zip.country?.includes(country));
  const zipCodesUnique = zipByCountry?.codes.filter(function (item, pos, self) {
    return self.indexOf(item) === pos;
  });
  return zipCodesUnique;
};

const env = process.env.REACT_APP_TARGET;

const ReturnReason = (props) => {
  const lan = props.language || localStorage.getItem("lan");
  const cardHeight = document.getElementById("inputCard")?.clientHeight;
  const id = localStorage.getItem("id");
  const key = localStorage.getItem("key");
  const hasBackPage = props.history?.action === "PUSH";
  const permittedCountries =
    env === "typ"
      ? ["IT", "ES", "ITA", "ESP"]
      : ["IT", "ES", "ITA", "ESP", "DE", "DEU", "FR", "FRA"];

  const [state, setState] = useState({
    booking: {
      date: moment().format("YYYY-MM-DD"),
    },
  });
  const [isAddressSet, setAddress] = useState(false);
  const [capError, setCapError] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [zipByCountry, setZipByCountry] = useState(
    state.deliveryAddress?.country?.toUpperCase()
  );
  // const [selectedReason, setReason] = useState(false);
  const [parcels, setParcels] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getShippingById(id, key);
    props.getParcels(id, key);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getShippingById(id, key);
    props.getParcels(id, key);
  }, [id, key]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...props.shippingData,
      booking: {
        date: moment().format("YYYY-MM-DD"),
      },
    }));
  }, [props.shippingData]);

  useEffect(() => {
    if (
      permittedCountries.find(
        (country) => country === state.deliveryAddress?.country?.toUpperCase()
      )
    ) {
      setZipByCountry(getZipCodes(state.deliveryAddress?.country));
    }
  }, [state.deliveryAddress?.country]);

  useEffect(() => {
    const formatted = props.parcels.map((parcel) => {
      const customData = parcel?.customData?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.key]: curr.value,
        };
      }, {});
      return {
        ...parcel,
        checked: true,
        customData,
      };
    });

    setParcels(formatted);
  }, [props.parcels]);

  useEffect(() => {
    if (!props.error && props.return && props.return.id) {
      // setTimeout(() => {
      //   props.history.push("/");
      // }, 3000);
    }
  }, [props.return]);

  const isCapPresent = () => {
    if (!state?.deliveryAddress?.postalCode) {
      return false;
    } else {
      return zipByCountry?.find(
        (zipCode) => zipCode === state?.deliveryAddress?.postalCode
      );
    }
  };

  const isCountryPresent = () => {
    if (!state.deliveryAddress?.country) {
      return true;
    } else {
      return permittedCountries.find(
        (country) => country === state?.deliveryAddress?.country
      );
    }
  };

  const inputList = [
    {
      placeholder: translate("referencePerson", lan),
      target: "referencePerson",
    },
    {
      placeholder: translate("companyName", lan),
      target: "companyName",
    },
    {
      placeholder: translate("address", lan),
      target: "street",
    },
    {
      placeholder: translate("buildingNr", lan),
      target: "buildingNr",
    },
    {
      placeholder: translate("postalCode", lan),
      target: "postalCode",
      options: zipByCountry,
      checkIsPresent: !isCapPresent(),
      errorMessage: translate("capNonDisponibile", lan),
      errorTrigger: true,
    },
    { placeholder: translate("city", lan), target: "city" },
    {
      placeholder: translate("country", lan),
      target: "country",
      options: permittedCountries,
      checkIsPresent: !isCountryPresent(),
      errorMessage: translate("nazioneNonDisponibile", lan),
      errorTrigger: true,
    },
    { placeholder: translate("note", lan), target: "note" },
  ];

  const handleChange = (target, value) => {
    setState((prevState) => ({
      ...prevState,
      deliveryAddress: {
        ...prevState.deliveryAddress,
        [target]: value,
      },
    }));
  };

  const handleSlotChange = (value) => {
    if (value.includes("-")) {
      const arr = value.split("-");
      setState((prevState) => ({
        ...prevState,
        booking: {
          ...prevState.booking,
          startTimeRange: arr[0].trim(),
          endTimeRange: arr[1].trim(),
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        booking: {
          ...prevState.booking,
          startTimeRange: null,
          endTimeRange: null,
        },
      }));
    }
  };

  const handleDayChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      booking: {
        ...prevState.booking,
        date: value,
      },
    }));
  };

  // const onValueChange = (event) => {
  //   setReason(event.target.value);
  //   setState((prevState) => ({
  //     ...prevState,
  //     returnReasons: event.target.value,
  //   }));
  // };

  const _isDisabled = () => {
    const nameList = [{ target: "referencePerson" }, { target: "companyName" }];
    const inputNameStatus = nameList.map(
      (input) =>
        !![input.target] &&
        state.deliveryAddress &&
        state.deliveryAddress[input.target] !== "" &&
        state.deliveryAddress[input.target] !== null
    );
    const inputNameAreValid = inputNameStatus.some((input) => !!input);
    const addressInputList = [
      { target: "street" },
      { target: "buildingNr" },
      { target: "postalCode" },
      { target: "city" },
      { target: "country" },
    ];
    const inputStatus = addressInputList.map(
      (input) =>
        !![input.target] &&
        state.deliveryAddress &&
        state.deliveryAddress[input.target] !== ""
    );
    const inputAreValid = inputStatus.every((input) => !!input);
    const atLeastOneParcelIsSelected =
      parcels.filter((p) => p.checked).length > 0;
    const bookingIsValid =
      state.booking.date &&
      state.booking.startTimeRange &&
      state.booking.endTimeRange;
    return (
      !inputNameAreValid ||
      !inputAreValid ||
      !isAddressSet ||
      !atLeastOneParcelIsSelected ||
      !bookingIsValid ||
      !isCapPresent()
    );
  };

  const _handleClick = async () => {
    // formatData(state);

    const newShipment = { ...state };

    const pickupAddress = { ...newShipment.deliveryAddress };
    const note = newShipment.deliveryAddress?.note;
    const senderAddress = pickupAddress;
    const deliveryAddress = { ...newShipment.pickupAddress };
    const endUserAddress = deliveryAddress;

    const req = {
      ...newShipment,
      channel: "WEBPORTAL",
      reference: newShipment.reference ? newShipment.reference + "R" : null,
      customerReference: newShipment.customerReference
        ? newShipment.customerReference + "R"
        : null,
      contractCode: newShipment.contractCode || newShipment.contract?.code,
      subContractCode:
        newShipment.subContractCode || newShipment.subContract?.code || null,
      pickupAddress,
      senderAddress,
      deliveryAddress,
      endUserAddress,
      shipment: {
        id: newShipment.id,
      },
      notes: note ? [{ note: note, noteType: "PICKUP" }] : null,
      parcels: parcels.filter((parcel) => !!parcel.checked).map(cleanParcel),
    };

    delete req.pickupAddress.note;
    delete req.estimatedDeliveryDate;
    delete req.subContract;
    delete req.contract;
    delete req.createdAt;
    delete req.updatedAt;
    delete req.guestKey;
    delete req.id;

    const response = await props.addReturn(req, key);
    if (response) {
      const stateObj = { isReturnConfirmed: true };
      props.history.push({
        pathname: "/",
        state: stateObj,
      });
    }
  };

  const _handleSelectParcels = (parcelId, checked) => {
    setParcels((prevParcels) => {
      return prevParcels.map((parcel) => {
        if (parcel.id === parcelId) {
          parcel = {
            ...parcel,
            checked,
          };
        }
        return parcel;
      });
    });
  };
  return (
    <div className="main-container">
      {/*   {isPopupOpen && (
        <Popup cardAlignItems="center" cardWidth="35%">
          <Text style={{ fontSize: 24 }} label="ResoSuccesso" />{" "}
          <Button
            handleclick={() => {
              const stateObj = { isReturnConfirmed: true };
              props.history.push({
                pathname: "/",
                state: stateObj,
              });
            }}
          >
            <Text style={{ color: "#fff" }} label="homepage" />
          </Button>
        </Popup>
      )} */}
      <SideSection hasBackPage={hasBackPage} />
      <div className="right-container">
        <MobileHeader backBtn />
        <div className="flex-row title-container">
          <div className="desktop-btn">
            <BackBtn>
              <Link to="/reso" className="flex-row-center">
                <ArrowLeft width={24} height={32} />
              </Link>
            </BackBtn>
          </div>

          <div className="title-style" style={{ paddingLeft: "32px" }}>
            <Text label="pianificaReso" />
          </div>
        </div>
        <div
          style={{
            padding: "0 5% 24px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Card
            id="inputCard"
            cardTitle={<Text label="indirizzoRitiro" />}
            className="card"
            half
            paddingCard="24px"
            // fontSize="1rem"
          >
            {inputList.map((input) => {
              if (input.options) {
                return (
                  <SelectZipCodes
                    disabled={isAddressSet}
                    label={input.placeholder}
                    target={input.target}
                    options={input.options}
                    handleChangeInput={handleChange}
                    value={
                      state?.deliveryAddress &&
                      state?.deliveryAddress[input.target]
                    }
                    errorTrigger={input.errorTrigger && input.checkIsPresent}
                    errorMessage={input.errorMessage}
                  />
                );
              }
              return (
                <Input
                  disabled={isAddressSet}
                  key={`key_${input.target}`}
                  target={input.target}
                  placeholder={input.placeholder}
                  handleChange={handleChange}
                  value={
                    state?.deliveryAddress &&
                    state?.deliveryAddress[input.target]
                  }
                />
              );
            })}
            <div
              style={{ flexDirection: "column" }}
              className="flex-row-center"
            >
              {capError && (
                <Text
                  style={{ color: "crimson", marginTop: 24 }}
                  label="capNonCorrisponde"
                />
              )}
              <Button
                disabled={!isCapPresent() || !isCountryPresent()}
                style={{ marginTop: capError ? 4 : 24 }}
                handleclick={() => setAddress(!isAddressSet)}
              >
                {isAddressSet ? (
                  <Text style={{ color: "#fff" }} label="modificaIndirizzo" />
                ) : (
                  <Text style={{ color: "#fff" }} label="salva" />
                )}
              </Button>
            </div>
          </Card>
          <Card
            minHeight={cardHeight}
            cardTitle={<Text label="dataRitiro" />}
            className="card"
            half
            paddingCard="16px 24px"
            cardAlignItems="center"
            cardJustifyContent={"center"}
          >
            {isAddressSet ? (
              <Calendar
                serviceCode={"R"}
                address={state.deliveryAddress}
                trackingId={id}
                key={key}
                noBtn
                noPadding
                handleSlotChange={handleSlotChange}
                handleDayChange={handleDayChange}
                setCapError={setCapError}
              />
            ) : (
              <>
                <Text
                  style={{ fontSize: 24, color: "var(--primary)" }}
                  label="confermaIndirizzo"
                />
                <Text
                  style={{ fontSize: 24, color: "var(--primary)" }}
                  label="vedereDate"
                />
              </>
            )}
            {/* {reasonsList.map((reason, i) => (
              <Checkbox
                key={`key_${reason.value}`}
                selected={selectedReason}
                label={reason.label}
                value={reason.value}
                handleSelect={onValueChange}
                last={i === reasonsList.length - 1 ? "true" : undefined}
              />
            ))} */}
          </Card>
        </div>
        <div
          style={{
            padding: "0 5% 24px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Card
            id="parcelsCard"
            cardTitle={<Text label="colli" />}
            className="card"
            half
            paddingCard="24px"
          >
            {parcels.map((parcel, i) => (
              <Parcel
                parcel={parcel}
                isLast={i === parcels.length - 1}
                handleSelectParcels={_handleSelectParcels}
              />
            ))}
          </Card>
        </div>
        <div
          className="flex-row-center"
          style={{ paddingBottom: 24, flexDirection: "column" }}
        >
          <Button handleclick={_handleClick} disabled={_isDisabled()}>
            <Text style={{ color: "#fff" }} label="pianificaReso" />
          </Button>
          {props.error ? (
            <div style={{ color: "red", marginTop: 12 }}>
              {props.error?.response?.data?.message}
            </div>
          ) : null}
        </div>
        <MobileFooter />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language.language,
  return: state.returns.data,
  tracking: state.shippings.data,
  parcels: state.returns.parcels,
  shippingData: state.shippings.shippingData,
  loading: state.returns.loading,
  error: state.returns.error,
});

export default connect(mapStateToProps, {
  getShippingById,
  addReturn,
  getParcels,
})(ReturnReason);

const cleanParcel = (parcel) => {
  let copy = { ...parcel };

  if (copy.customData?.Parcel_Number_Return) {
    copy = {
      ...copy,
      customData: [
        {
          key: "Parcel_Number",
          value: copy.customData?.Parcel_Number_Return,
        },
      ],
    };
  } else {
    delete copy.customData;
  }

  delete copy.customerBarcode;
  delete copy.barcode;
  delete copy.reference;
  delete copy.checked;
  return copy;
};

const Parcel = ({ parcel, isLast, handleSelectParcels }) => {
  return (
    <div
      // onClick={() => handleSelectParcels(parcel.id, !parcel.checked)}
      style={{
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        paddingBottom: isLast ? 0 : 16,
        marginBottom: isLast ? 0 : 16,
        borderBottom: isLast ? 0 : "1px solid #ccc",
        userSelect: "none",
      }}
    >
      <div style={{ flex: 1 }}>
        <div
          style={{
            width: 16,
            height: 16,
            borderRadius: 50,
            border: "1px solid #ccc",
            backgroundColor: parcel.checked ? "var(--primary)" : "unset",
          }}
        ></div>
      </div>
      <div style={{ flex: 3, color: "var(--primary)" }}>
        {parcel?.customData?.Parcel_Number || parcel?.customerBarcode}
      </div>
      <div style={{ flex: 5, color: "var(--primary)" }}>
        {parcel?.customData?.family || ""}
      </div>
    </div>
  );
};

// const reasonsList = [
//   { label: <Text label="acquistoErrore" />, value: "acquisto per errore" },
//   {
//     label: <Text label="migliorPrezzo" />,
//     value: "miglior prezzo disponibile",
//   },
//   { label: <Text label="qualitaNonAdeguate" />, value: "qualità non adeguate" },
//   {
//     label: <Text label="incomparabile" />,
//     value: "incomparabile e inadeguato",
//   },
//   {
//     label: <Text label="articoloDanneggiato" />,
//     value: "articolo danneggiato, confezione esterna intatta",
//   },
//   { label: <Text label="accessoriMancanti" />, value: "accessori mancanti" },
//   {
//     label: <Text label="articoloConfezioneDanneggiati" />,
//     value: "articolo e confezione esterna danneggiati",
//   },
//   {
//     label: <Text label="articoloErrato" />,
//     value: "ricevuto articolo errato",
//   },
// ];
