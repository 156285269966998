import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Text, translate } from "../../../assets/lemmata";
import { Card } from "../../../components/Cards/Cards";
import { ButtonLink } from "../../../shared/Buttons";
import { Spinner } from "../../../shared/Spinner";
import { ReactComponent as Timer } from "../../../assets/icons/timer-white.svg";

const SchedulingCard = ({
  guestKey,
  shipping,
  loading,
  isShippingDelivering,
  isShippingDelivered,
  language,
}) => {
  const formatTimeData = (timeData) => {
    const timeDataSplitted = timeData?.split("-");
    let dataStart = timeDataSplitted[0].trim();
    let dataEnd = timeDataSplitted[1].trim();
    if (dataStart.length < 3) {
      dataStart = dataStart + ":00";
    }
    if (dataEnd.length < 3) {
      dataEnd = dataEnd + ":00";
    }
    return isShippingDelivered
      ? `${translate("betweenHours", language)} ${dataStart} ${translate(
          "andHours",
          language
        )} ${dataEnd}`
      : `${translate("fromHours", language)} ${dataStart} ${translate(
          "toHours",
          language
        )} ${dataEnd}`;
  };

  return (
    <>
      <Card
        className="card"
        paddingTopContainer="0"
        paddingCard="16px 24px 24px 24px"

        /*  cardTitle={<Text label="prenotazione" />} */
        // cardJustifyContent={
        //   !isShippingDelivering() && "space-between"
        // }
      >
        {loading ? (
          <Spinner />
        ) : // ) : !shipping?.estimatedDeliveryDate ? (
        shipping?.events <= 1 ? (
          // <div>
          <div
            style={{
              alignItems: "center",
              minHeight: 192,
            }}
            className="flex-row-center"
          >
            <Text style={{ fontSize: 18 }} label="ConsegnaNonInCarico" />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: !isShippingDelivering
                ? "space-between"
                : "flex-start",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <h2 className="mb-05">
              <Text
                style={{
                  fontWeight: 500,
                  fontSize: "0.9em",
                  color: "var(--primary)",
                }}
                label="prenotazione"
              />
            </h2>
            <div>
              <div>
                <Text label="prenotazionePrevista" />
              </div>
              <div className="deliveryDate">
                {/* {shipping?.estimatedDeliveryDate ? (
                        moment(shipping.estimatedDeliveryDate).format( */}
                {shipping?.booking?.bookingDate ? (
                  <div>
                    {moment(shipping?.booking?.bookingDate).format(
                      "DD MMMM YYYY "
                    )}
                    <br />
                    {formatTimeData(shipping?.booking?.slotTimeRange)}
                  </div>
                ) : (
                  <Text
                    style={{ fontSize: "1.1rem" }}
                    label="dataConsegnaNonDisponibile"
                  />
                )}
              </div>
            </div>
            {!isShippingDelivering &&
              shipping?.serviceCode?.toLowerCase() !== "r" && (
                <div>
                  <Text
                    style={{
                      fontWeight: 300,
                      color: "var(--primary)",
                    }}
                    className="light-weight"
                    label="prenotazionePersonalizzata"
                  />
                  <div className="flex-row-center">
                    <Link
                      className="link-style"
                      to={
                        guestKey
                          ? {
                              pathname: "/booking",
                            }
                          : {
                              pathname: "/richiesta-link",
                              state: {
                                action: "BOOKING",
                                title: "richiestaPianifica",
                              },
                            }
                      }
                    >
                      <ButtonLink
                        color="#fff"
                        backgroundColor="var(--primary)"
                        marginTop="12px"
                      >
                        <Timer
                          width={24}
                          height={24}
                          style={{ marginRight: 8 }}
                        />
                        <Text style={{ color: "#fff" }} label="pianifica" />
                      </ButtonLink>
                    </Link>
                  </div>
                </div>
              )}
          </div>
        )}
      </Card>
    </>
  );
};

export default SchedulingCard;
