import axios from "axios";
import moment from "moment";
import api from "../server/api";

const GET_ALL_PUDOS_REQUEST = "TYP/GET_ALL_PUDOS_REQUEST";
const GET_ALL_PUDOS_SUCCESS = "TYP/GET_ALL_PUDOS_SUCCESS";
const GET_ALL_PUDOS_FAILURE = "TYP/GET_ALL_PUDOS_FAILURE";

const initialState = {
  loading: false,
  error: false,
  data: "",
};

export default function reducerPudos(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PUDOS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_PUDOS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_ALL_PUDOS_FAILURE:
      return { ...state, loading: false, data: "", error: action.payload };

    default:
      return state;
  }
}

export const getAllPudos = (address, key) => {
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_PUDOS_REQUEST,
    });

    try {
      const { url, method, headers, data, params } = api.getAllPudos(
        address,
        key
      );

      const res = await axios({
        url,
        method,
        headers,
        data,
        params,
      });

      let pudos = res.data.pudos;

      pudos = pudos?.map((pudo) => {
        const slotsArray = pudo?.slots?.map((weekDay) => {
          return weekDay?.weekDays?.reduce((acc, curr) => {
            return {
              ...acc,
              [curr]:
                moment().set("H", weekDay.fromTime).format("HH") +
                ":" +
                moment().set("m", weekDay.fromTimeMinutes).format("mm") +
                " - " +
                moment().set("H", weekDay.toTime).format("HH") +
                ":" +
                moment().set("m", weekDay.toTimeMinutes).format("mm"),
            };
          }, {});
        });

        const slots = joinArray(slotsArray);

        return {
          ...pudo,
          slots,
        };
      });

      dispatch({
        type: GET_ALL_PUDOS_SUCCESS,
        payload: {
          count: res.data.count,
          pudos,
        },
      });

      return pudos.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_PUDOS_FAILURE,
        payload: error,
      });
    }
  };
};

export const joinArray = (arr) => {
  const joinedKeys = Object.keys(
    arr.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {})
  );
  const obj = joinedKeys.reduce((acc, curr) => {
    const values = arr
      .filter((obj) => !!obj[curr])
      .reduce((acc2, curr2) => {
        return [...acc2, curr2[curr]];
      }, []);

    return {
      ...acc,
      [curr]: values,
    };
  }, {});

  return obj;
};
