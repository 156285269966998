import React, { useEffect, useState } from "react";
import { Input, InputWithButton } from "../../shared/Inputs";
import { ReactComponent as Package } from "../../assets/icons/packageBlu.svg";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone-solid.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/icons/magnifying-glass.svg";
// import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

import { Text, translate } from "../../assets/lemmata";
import "./FindShipmentsSection.scss";
import { Button, WhatsappBtn } from "../../shared/Buttons";
import { useHistory } from "react-router-dom";
import { Popup } from "../Popups/Popup";
import { OtpInputBox } from "../SideSection/SideSection";
import { BtnSpinner } from "../../shared/Spinner";
import { requestShipmentOtp } from "../../reducers/account";
import { useDispatch, useSelector } from "react-redux";
import { getAllShippings } from "../../reducers/shippings";
import CountdownTimer from "../Timer/Timer";

const otpInputs = [
  { target: 1 },
  { target: 2 },
  { target: 3 },
  { target: 4 },
  { target: 5 },
  { target: 6 },
];

export default function FindShipmentsSection(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [trackingCode, setTrackingCode] = useState(null);
  const [state, setState] = useState({});
  const [prefix, setPrefix] = useState("+39");
  const [verificated, setVerificated] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [language, setLanguage] = useState(
    props.language || localStorage.getItem("lan") || "it"
  );
  // const requestSuccess = useSelector((state) => state.account.success);
  const requestError = useSelector((state) => state.account.error);
  const errorVerification = useSelector((state) => state.shippings.errorList);

  const handleClick = () => {
    history.push(`/?id=${trackingCode}`);
  };

  const inputList = [
    {
      placeholder: translate("email", language),
      target: "email",
      type: "email",
      icon: <Mail fill="var(--primary)" width={24} height={24} />,
      disabled: !!state.mobile,
      autocomplete: "off",
    },
    {
      placeholder: translate("phoneNumber", language),
      target: "mobile",
      type: "tel",
      icon: <Phone fill="var(--primary)" width={24} height={24} />,
      prefixSelect: true,
      prefixSelectMarginTop: 10,
      disabled: !!state.email,
      autocomplete: "off",
    },
  ];

  const handleChange = (target, value) => {
    setState((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const handlePrefixSelection = (value) => {
    setPrefix(value);
  };

  const handleRequest = async () => {
    const request = await dispatch(
      requestShipmentOtp({
        email: state?.email || null,
        mobile: state?.mobile ? prefix + state.mobile : null,
      })
    );
    if (request) {
      setIsPopupOpen(true);
    }
  };

  const handleOtpConfirm = async (otp) => {
    const request = await dispatch(getAllShippings({ password: otp }));

    if (request) {
      setVerificated(true);
      localStorage.setItem("otpCode", otp);
      setTimeout(() => {
        history.push(`/shipments-list`);
      }, 2000);
    }
  };

  useEffect(() => {
    if (props.language) {
      setLanguage(props.language);
    }
  }, [props.language]);

  return (
    <>
      {/*////////////////////////// TRACKING SEARCHBAR ///////////////////////*/}

      {isPopupOpen && (
        <OtpPopup
          secondPageTitle="verificaOtp"
          mobileSubtitle="verificaOtpSub"
          mailSubtitle="verificaOtpSubMail"
          secondPageButtonLabel="verifica"
          verificated={verificated}
          setVerificated={setVerificated}
          close={() => {
            setIsPopupOpen(!isPopupOpen);
          }}
          state={state}
          handleOtpConfirm={handleOtpConfirm}
          errorVerification={errorVerification}
          requestError={requestError}
          handleRequest={handleRequest}
        />
      )}
      <div className="tracking-section">
        <div className="half-section">
          <Text
            style={{
              fontSize: "1.8rem",
              fontWeight: "bold",
              paddingBottom: "16px",
              textAlign: "center",
            }}
            label="tracciaSpedizione"
          />
          <Text
            style={{
              //             fontWeight: 600,
              minHeight: "66px",
              paddingBottom: "16px",
              textAlign: "center",
            }}
            label="vediSingoloOrdine"
          />

          <Input
            style={{ minWidth: "350px", maxWidth: "350px" }}
            placeholder={translate("numeroTracking", language)}
            onChange={(e) => setTrackingCode(e.target.value)}
            type="text"
            icon={<Package fill="var(--primary)" className="input-icon" />}
          />
          <div
            className="flex-row-center"
            style={{ marginBottom: "4rem", marginTop: "4px" }}
          >
            <Button
              marginTop="4px"
              backgroundColor="var(--primary)"
              color="#fff"
              border="1px solid var(--primary)"
              style={{ position: "relative" }}
              handleclick={() => handleClick()}
              disabled={!trackingCode}
            >
              <Text style={{ fontWeight: 400 }} label="tracciaOra" />
              <MagnifyingGlass
                fill="var(--magnifyingGlassColor)"
                style={{
                  position: "absolute",
                  right: 12,
                  top: 12,
                  width: 24,
                  height: 24,
                }}
              />
            </Button>
          </div>
        </div>
        <div className="half-section">
          <Text
            style={{
              fontSize: "1.8rem",
              fontWeight: "bold",
              paddingBottom: "16px",
            }}
            label="trovaSpedizioni"
          />
          <Text
            style={{
              paddingBottom: "16px",
              textAlign: "center",
              maxWidth: 500,
            }}
            label="inserisciNumeroSpedizioni"
          />

          {inputList.map((input, i) => (
            <>
              <Input
                style={{ minWidth: "350px", maxWidth: "350px" }}
                key={`key_${input.target}`}
                target={input.target}
                placeholder={input.placeholder}
                handleChange={handleChange}
                type={input.type}
                icon={input.icon}
                prefixSelect={input.prefixSelect}
                prefixSelectHandleChange={handlePrefixSelection}
                prefixSelectMarginTop={input.prefixSelectMarginTop}
                disabled={input.disabled}
                autocomplete={input.autocomplete}
              />
              {i === 0 && <Text label="oppure" />}
            </>
          ))}
          <Text
            style={{
              fontSize: "0.6rem",
              maxWidth: 350,
              textAlign: "center",
              marginTop: 8,
              marginBottom: 8,
            }}
            label="disclaimerUtilizzoDati"
          />
          {requestError === "wrong mail or mobile number" && (
            <Text
              style={{
                color: "crimson",
                paddingTop: 12,
                fontSize: "12px",
                fontWeight: 500,
              }}
              label="mailNumeroNonCorretti"
            />
          )}
          <div
            className="flex-row-center"
            style={{ marginBottom: "4rem", marginTop: "4px" }}
          >
            <Button
              marginTop="4px"
              backgroundColor="var(--primary)"
              color="#fff"
              border="1px solid var(--primary)"
              handleclick={() => handleRequest()}
              disabled={!state.mobile && !state.email}
            >
              <Text
                style={{ fontWeight: 400 }}
                label="vediSpedizioniCollegate"
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

const OtpPopup = (props) => {
  const [isTimeExpired, setIsTimeExpired] = useState(false);

  const [timeLeft, setTimeLeft] = useState("unsetted");
  const FIVE_MINUTES_IN_MS = 5 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterFiveMinutes = NOW_IN_MS + FIVE_MINUTES_IN_MS;
  useEffect(() => {
    if (timeLeft === "unsetted") {
      setTimeLeft(dateTimeAfterFiveMinutes);
    }
  }, []);
  let defaultCode = otpInputs.reduce(
    (previousValue) => previousValue + "*",
    ""
  );
  const [otpCode, setOtpCode] = useState(defaultCode);

  const handleCodeChange = (target, value, e) => {
    if (value.length === 1) {
      let newCode = otpCode;
      newCode =
        newCode.substring(0, target - 1) + value + newCode.substring(target);
      setOtpCode(newCode);
    } else if (value.length > 1) {
      let numArray = value.split("");
      // if (!!otpCode[target] && otpCode[target] !== "*") {
      //   console.log("qui", otpCode[target]);
      //   numArray.slice(1, 6);
      //   console.log("numArray", numArray);
      // }
      // setOtpCode(defaultCode);
      let newCode = otpCode;
      numArray.forEach((num, i) => {
        newCode = newCode.substring(0, i) + num + newCode.substring(i + 1);
      });
      // console.log("newCode", newCode);
      setOtpCode(newCode.slice(0, 6));
    } else {
      let newCode = otpCode;
      newCode =
        newCode.substring(0, target - 1) + "*" + newCode.substring(target);
      setOtpCode(newCode);
    }
  };

  const handleNewOtpRequest = () => {
    setTimeLeft(dateTimeAfterFiveMinutes);
    setTimeout(() => {
      setOtpCode(defaultCode);
      setIsTimeExpired(false);
      props.handleRequest();
    }, 200);
  };
  return (
    <Popup>
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2>
            <Text label={props.secondPageTitle} />
          </h2>
          {!isTimeExpired && timeLeft !== "unsetted" && (
            <CountdownTimer
              targetDate={timeLeft}
              setIsTimeExpired={setIsTimeExpired}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            fontSize: "0.9rem",
            flexWrap: "wrap",
            marginTop: "0.5rem",
          }}
        >
          {props.state?.mobile ? (
            <>
              <Text
                label={props.mobileSubtitle}
                style={{ paddingRight: 4, color: "var(--primary)" }}
              />
              <p style={{ fontWeight: 600 }}>
                <span style={{ color: "var(--primary)" }}>
                  {` ${props.state?.mobile}`}
                </span>
              </p>
            </>
          ) : (
            <>
              <Text
                label={props.mailSubtitle}
                style={{ paddingRight: 4, color: "var(--primary)" }}
              />
              <p style={{ fontWeight: 600 }}>
                {" "}
                <span style={{ color: "var(--primary)" }}>
                  {" "}
                  {props.state?.email}
                </span>
              </p>
            </>
          )}
        </div>

        <OtpInputBox
          otpInputs={otpInputs}
          handleCodeChange={handleCodeChange}
          disabled={isTimeExpired}
          otpCode={otpCode}
        />
        {!isTimeExpired && (
          <Text
            onClick={() => handleNewOtpRequest()}
            className="request-otp-text"
            label="richiediAltroCodice"
          />
        )}

        {props.errorVerification && !isTimeExpired && (
          <p
            style={{
              fontSize: "0.8rem",
              fontWeight: "500",
              color: "crimson",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {props.errorVerification}
          </p>
        )}
        {props.requestError && !isTimeExpired && (
          <p
            style={{
              fontSize: "0.8rem",
              fontWeight: "500",
              color: "crimson",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {props.requestError}
          </p>
        )}
        {isTimeExpired && (
          <Text
            style={{
              fontSize: "0.8rem",
              fontWeight: "500",
              color: "crimson",
              textTransform: "uppercase",
              textAlign: "center",
              // paddingLeft: "56px",
            }}
            label="codiceScaduto"
          />
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
        >
          <Close onClick={props.close} width={24} height={24} />
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!isTimeExpired ? (
            <WhatsappBtn
              style={{
                backgroundColor: props.verificated && "var(--secondary)",
                border: props.verificated && "1px solid var(--secondary)",
                position: "relative",
              }}
              disabled={!!otpCode && otpCode.includes("*")}
              handleclick={() => {
                props.handleOtpConfirm(otpCode);
              }}
            >
              {!props.loading ? (
                <>
                  {props.verificated ? (
                    <b>
                      <Text
                        style={{ fontSize: 12, color: "var(--primary)" }}
                        label={"numeroVerificato"}
                      />
                    </b>
                  ) : (
                    <b>
                      <Text
                        style={{ fontSize: 12 }}
                        label={props.secondPageButtonLabel}
                      />
                    </b>
                  )}
                </>
              ) : (
                <BtnSpinner color="#fff" />
              )}
              <Check
                stroke="var(--primary)"
                style={{
                  width: 24,
                  height: 24,
                  position: "absolute",
                  right: 16,
                  top: 10,
                }}
              />
            </WhatsappBtn>
          ) : (
            <WhatsappBtn handleclick={() => handleNewOtpRequest()}>
              {!props.loading ? (
                <>
                  {props.verificated ? (
                    <b>
                      <Text
                        style={{ fontSize: 12, color: "var(--primary)" }}
                        label={"numeroVerificato"}
                      />
                    </b>
                  ) : (
                    <b>
                      <Text
                        style={{ fontSize: 12 }}
                        label={"richiediNuovoOtp"}
                      />
                    </b>
                  )}
                </>
              ) : (
                <BtnSpinner color="#fff" />
              )}
            </WhatsappBtn>
          )}
        </div>
      </>
    </Popup>
  );
};
