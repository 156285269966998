export const capitalize = (str) => {
  if (str) {
    str = str.toLowerCase();

    const arrOfWords = str.split(" ");
    const arrOfWordsCased = [];

    for (let i = 0; i < arrOfWords.length; i++) {
      const char = arrOfWords[i].split("");
      if (char[0]) {
        char[0] = char[0].toUpperCase();
      }

      arrOfWordsCased.push(char.join(""));
    }
    return arrOfWordsCased.join(" ");
  }
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const cleanUppercase = (string) => {
  if (!string) return "";
  return string.split(" ").map(capitalizeFirst).join(" ");
};

export const capitalizeFirst = (string) => {
  if (!string) return "";
  return string[0].toUpperCase() + string.slice(1, string.length).toLowerCase();
};
