import React from "react";
import { Text, translate } from "../../../assets/lemmata";
import { ReactComponent as Check } from "../../../assets/icons/check-circle.svg";
const ThankSection = (props) => {
  return (
    <div className="flex-column-center" style={{ textAlign: "center" }}>
      <Check width={100} />
      <h2 style={{ color: "var(--primary)" }}>
        {translate("thankForRatingTitle", props.language)}
      </h2>

      <p style={{ color: "var(--primary)" }}>
        {translate("thankForRatingSubtitle", props.language)}
      </p>
    </div>
  );
};

export default ThankSection;
