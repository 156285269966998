import "./TrackingPage.scss";
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import moment from "moment";
import { Text, translate } from "../../assets/lemmata";
import { ReactComponent as Phone } from "../../assets/icons/smartphone.svg";
import {
  getShippingLogo,
  getTrackingData,
  getTrackingDataWithCode,
  getTrackingDataWithCustomerCode,
} from "../../reducers/shippings";
import { setReduxLanguage } from "../../reducers/language";
import { getAllPudos } from "../../reducers/pudos";
import { activateNotifications, sendMessage } from "../../reducers/messages";
// import FindShipmentsSection from "../../components/FindShipmentsSection/FindShipmentsSection";
import LanguageSelection from "../../components/LanguageSelection/LanguageSelection";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection, {
  PopUpWhatsapp,
  PopUpContact,
  HelpSection,
} from "../../components/SideSection/SideSection";
import TrackingSection from "../../components/TrackingSection/TrackinSection";
import { groupEventsByState } from "../../components/TrackingSection/FormatEventsFunc";
import PudoCard from "./components/PudoCard";
import SchedulingCard from "./components/SchedulingCard";
import ReturnCard from "./components/ReturnCard";
import CustomMessageCard from "./components/CustomMessageCard";
import PodCard from "./components/PodCard";
import PendingProvisionCard from "./components/PendingProvisionCard";
import ConfirmedProvisionCard from "./components/ConfirmedProvisionCard";
import EstimatedDeliveryDateCard from "./components/EstimatedDeliveryDateCard";
import ConfirmedReturnPopup from "./components/ConfirmedReturnPopup";
import StoppedShippingReasonCard from "./components/StoppedShippingReasonCard";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const TrackingPage = (props) => {
  const dispatch = useDispatch();
  const optInEnabled = useSelector(
    ({ shippings }) => shippings.data?.optInEnabled || null
  );
  const shippingLogo = useSelector(
    ({ shippings }) => shippings.shippingLogo || null
  );
  const pudos = useSelector(({ pudos }) => pudos.data?.pudos || null);

  const isReturnConfirmed = props.location?.state?.isReturnConfirmed;

  const [language, setLanguage] = useState(
    localStorage.getItem("lan") ||
      props.language ||
      navigator.language.toLowerCase()
  );
  const customMessage = props.shipping?.notes?.find(
    (note) => note.noteType === "MERCHANT_TO_ENDUSER"
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isReturnConfirmedPopupOpen, setIsReturnConfirmedPopupOpen] =
    useState(false);
  const [message, setMessage] = useState(false);
  const [whatsappInfo, setWhatsappInfo] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [verificationComplete, setVerificationComplete] =
    useState(optInEnabled);

  const id = useQuery().get("id") || localStorage.getItem("id");
  const key = useQuery().get("key") || localStorage.getItem("key");
  const urlId = useQuery().get("id");
  const urlKey = useQuery().get("key");
  const reference = useQuery().get("reference") || null;
  const customerCode = useQuery().get("customerCode");
  const customerReference = useQuery().get("customerReference");
  const contractCode = useQuery().get("contractCode");

  ///////////// LOGICHE PER LA GESTIONE DELLE CARDS
  const isShippingDelivered = () => {
    if (props?.shipping?.events) {
      const isDelivered =
        props.shipping.events[props.shipping.events.length - 1]?.eventCode ===
        1;
      return isDelivered;
    } else {
      return false;
    }
  };

  const isShippingDelivering = () => {
    if (props?.shipping?.events) {
      const isDelivering =
        props.shipping.events[props.shipping?.events?.length - 1]?.eventCode ===
        52;
      return isDelivering;
    } else {
      return false;
    }
  };

  const isReturnPickedUp = () => {
    if (props?.shipping?.events) {
      const isPickedUp = props.shipping?.events.some(
        (event) => event.eventCode === 37
      );
      const isReturn = props.shipping.serviceCode === "R";
      return isPickedUp && isReturn;
    } else {
      return false;
    }
  };

  const isReturnAvailable = () => {
    return (
      props.shipping?.serviceCode?.toLowerCase() !== "sc" &&
      props.shipping?.serviceCode?.toLowerCase() !== "r" &&
      isShippingDelivered() &&
      props.shipping.contractOptions?.endUserReturnAvailable &&
      props.shipping.provisionModeId !== 1 &&
      !props.shipping?.returnId
    );
  };

  const isAssistanceAvailable = () => {
    return (
      !!key &&
      !!props.shipping &&
      window.location?.pathname !== "/find-shipments" &&
      window.location?.pathname !== "/shipments-list"
    );
  };

  const isWhatsappAvailable =
    !!key &&
    props.shipping?.contractOptions?.whatsappStream &&
    window.location?.pathname !== "/find-shipments" &&
    window.location?.pathname !== "/shipments-list";

  const isSchedulingAvailable = () => {
    return (
      !isShippingDelivered() &&
      !props.shipping.addressChangeRequestPending &&
      // !isShippingDelivering() &&
      !isReturnPickedUp() &&
      !props.shipping?.warehouseStorageId &&
      // props.shipping.contractOptions.endUserReturnAvailable &&
      (props.shipping?.serviceCode?.toLowerCase() === "sc" ||
        props.shipping?.serviceCode?.toLowerCase() === "r" ||
        props.shipping?.serviceCode?.toLowerCase() === "d" ||
        props.shipping?.serviceCode?.toLowerCase() === "bc")
      // ||
      // props.shipping?.serviceCode?.toLowerCase() === "r"
    );
  };

  /////////<-- LOGICHE PER LA GESTIONE DELLE CARDS

  const inputList = [
    {
      placeholder: translate("oggettoRichiesta", language),
      target: "subject",
    },
    {
      placeholder: translate("testoRichiesta", language),

      target: "body",
      textarea: true,
    },
  ];

  const whatsappInputList = [
    // {
    //   placeholder: lan.includes("it")
    //     ? "Inserisci il tuo nome"
    //     : "Insert your name",
    //   target: "name",
    // },
    {
      placeholder: translate("inserisciTuoNumero", language),
      target: "to",
      icon: <Phone width={24} height={24} />,
      errorLabel: "numeroNonValido",
      prefixSelect: true,
    },
  ];

  const _handleClick = async () => {
    const response = await props.sendMessage(message, id);
    if (response) {
      setIsPopupOpen(false);
    }
  };

  const _handleWhatsappClick = async () => {
    let number = whatsappInfo.to;
    if (whatsappInfo.to.startsWith("+")) {
      number = whatsappInfo.to.substring(1);
    }
    if (whatsappInfo.to.startsWith("00")) {
      number = whatsappInfo.to.substring(2);
    }

    const response = await activateNotifications(whatsappInfo.name, number);

    if (response) {
      setIsPopupOpen(false);
    }
  };

  const handleChange = (target, value) => {
    setMessage({
      ...message,
      [target]: value,
    });
  };

  const handleWhatsappChange = (target, value) => {
    setWhatsappInfo({
      ...whatsappInfo,
      [target]: value,
    });
  };

  useEffect(() => {
    if (
      urlId ||
      urlKey ||
      reference ||
      customerCode ||
      customerReference ||
      contractCode
    ) {
      localStorage.removeItem("customerCode");
      localStorage.removeItem("contractCode");
      localStorage.removeItem("customerReference");
      localStorage.removeItem("reference");
      localStorage.removeItem("id");
      localStorage.removeItem("key");
    }
    if (!urlId && !id && !customerCode && !customerReference && !contractCode) {
      setShipping("not_find");
    }
  }, []);

  useEffect(() => {
    if (reference) {
      localStorage.removeItem("id");
      localStorage.removeItem("key");
      dispatch(getTrackingDataWithCode(reference, contractCode, language));
    }
  }, [reference, language]);

  useEffect(() => {
    if (customerReference) {
      localStorage.removeItem("id");
      localStorage.removeItem("key");
      dispatch(
        getTrackingDataWithCustomerCode(
          customerReference,
          customerCode,
          language
        )
      );
    }
  }, [customerReference, language]);

  useEffect(() => {
    if (urlId || urlKey) {
      window.scrollTo(0, 0);
      localStorage.setItem("id", id);

      if (urlKey) {
        localStorage.setItem("key", urlKey);
      }
      dispatch(getTrackingData(id, key, language));
      if (key) {
        dispatch(getAllPudos(id, key));
      }
    } else if (id || key) {
      dispatch(getTrackingData(id, key, language));
      if (key) {
        dispatch(getAllPudos(id, key));
      }
    }
  }, [id, key, language]);

  useEffect(() => {
    if (
      language.includes("it") ||
      language.includes("en") ||
      language.includes("es") ||
      language.includes("de") ||
      language.includes("fr")
    ) {
      localStorage.setItem("lan", language);
      moment.locale(language);
      props.setReduxLanguage(language);
    } else {
      localStorage.setItem("lan", "en");
      moment.locale("en");
      props.setReduxLanguage("en");
      setLanguage("en");
    }
  }, [language]);

  useEffect(() => {
    if (props.shipping) {
      if (props.shipping?.contractId) {
        dispatch(getShippingLogo(props.shipping.contractId));
      }
      if (props.shipping?.shipmentId) {
        setShipping(true);
      } else {
        setShipping("not_find");
      }
    }
    if (props.error) {
      // setShipping("not_find");
      localStorage.removeItem("customerCode");
      localStorage.removeItem("customerReference");
      localStorage.removeItem("reference");
      localStorage.removeItem("id");
      localStorage.removeItem("key");
    }
  }, [props.shipping, props.error]);

  useEffect(() => {
    setVerificationComplete(optInEnabled);
  }, [optInEnabled]);

  useEffect(() => {
    if (!!isReturnConfirmed) {
      setIsReturnConfirmedPopupOpen(true);
    }
  }, [isReturnConfirmed]);

  const selectLanguage = (lan) => {
    setLanguage(lan);
    setReduxLanguage(lan);
  };

  const getKoReason = (eventID) => {
    const koEventId = props.shipping?.events?.find(
      (event) => event.eventCode === 11 && event.eventId === eventID
    )?.eventId;

    if (props.shipping?.parcels?.length > 0) {
      const koParcel = props.shipping.parcels?.map((parcel) =>
        parcel?.events?.find((event) => event.shipmentEventId === koEventId)
      );

      if (!!koParcel[0]) {
        return koParcel[0]?.eventName;
      }
    }
    return null;
  };

  const hasPassedCheckIn = props.shipping?.events?.some(
    (e) => e.eventCode === 4
  );

  const isRetired = props.shipping?.events?.some((e) => e.eventCode === 37);

  const shippingIsStopped =
    props.shipping?.events &&
    (props.shipping?.events[props.shipping?.events.length - 1]?.eventCode ===
      35 ||
      props.shipping?.events[props.shipping?.events.length - 1]?.eventCode ===
        76 ||
      props.shipping?.events[props.shipping?.events.length - 1]?.eventCode ===
        77);

  const shippingIsCanceled =
    props.shipping?.events &&
    props.shipping?.events[props.shipping?.events.length - 1]?.eventCode === 96;

  const isEstimatedDeliveryDateVisible = () => {
    if (process.env.REACT_APP_TARGET !== "typ") {
      return false;
    }

    if (props.shipping?.serviceCode?.toLowerCase() === "r") {
      return (
        /*        props.shipping?.contractId === 6 && */
        !!props.shipping?.estimatedDeliveryDate &&
        !!isRetired &&
        !isShippingDelivered() &&
        !shippingIsStopped
      );
    } else if (props.shipping?.serviceCode?.toLowerCase() === "n") {
      return (
        /*   props.shipping?.contractId === 6 && */
        !!props.shipping?.estimatedDeliveryDate &&
        !!hasPassedCheckIn &&
        !isShippingDelivered() &&
        !shippingIsStopped &&
        (!props.shipping?.booking ||
          moment(props.shipping?.booking?.bookingDate).isAfter(moment()))
      );
    } else return false;
  };

  const hasChatWidget =
    process.env.REACT_APP_TARGET === "typ" &&
    !!key &&
    !!props.shipping &&
    !["BC", "SC"].some(
      (serviceCode) => serviceCode === props.shipping?.serviceCode
    );

  return (
    <>
      {!!isReturnConfirmedPopupOpen &&
        !!props.shipping?.contractOptions?.returnFeedbackText && (
          <ConfirmedReturnPopup
            message={props.shipping?.contractOptions?.returnFeedbackText}
            close={() =>
              setIsReturnConfirmedPopupOpen(!isReturnConfirmedPopupOpen)
            }
          />
        )}
      {isPopupOpen === "contact" && (
        <PopUpContact
          title="scriviRichiesta"
          close={() => setIsPopupOpen(false)}
          inputs={inputList}
          disabled={!message.subject || !message.body}
          handleClick={_handleClick}
          handleChange={handleChange}
          buttonLabel="invia"
        />
      )}
      {isPopupOpen === "whatsapp" && (
        <PopUpWhatsapp
          title="whatsapp"
          subtitle="whatsappSub"
          secondPageTitle="verificaOtp"
          secondPageSubtitle="verificaOtpSub"
          close={() => setIsPopupOpen(false)}
          inputs={whatsappInputList}
          disabled={!whatsappInfo?.to}
          handleclick={_handleWhatsappClick}
          handleChange={handleWhatsappChange}
          buttonLabel="attivaServizio"
          secondPageButtonLabel="verifica"
          shipmentId={id}
          verificationComplete={verificationComplete}
          setVerificationComplete={setVerificationComplete}
        />
      )}
      <div className="main-container">
        <SideSection
          id={props.match.params.id}
          key={props.match.params.key}
          isReturnAvailable={isReturnAvailable}
          isAssistanceAvailable={isAssistanceAvailable}
          isWhatsappAvailable={isWhatsappAvailable}
          serviceCode={props.shipping?.serviceCode}
        />
        <div className="right-container">
          <MobileHeader />
          <div className="main-header">
            <div className="container-logo">
              {shippingLogo && (
                <img
                  className="logo"
                  src={`data:${shippingLogo.contentType};base64, ${shippingLogo.content}`}
                  alt={shippingLogo.fileName}
                />
              )}
            </div>

            <LanguageSelection selectLanguage={selectLanguage} />
          </div>

          {!props.loading && props.error && (
            <div className="error-container">
              <Text
                label="idNonTrovato"
                style={{ marginBottom: "1rem", color: "var(--primary)" }}
              />
              <a className="btn-mobile-style" href={`/find-shipments`}>
                <Text
                  style={{ fontWeight: 400, fontSize: "0.9rem" }}
                  label="tornaAllaRicerca"
                />
              </a>
            </div>
          )}

          {!props.loading && shipping && (
            <>
              {shipping === "not_find" ? (
                <>
                  <Redirect to="find-shipments" />
                </>
              ) : (
                <div style={{ padding: "32px 5%" }}>
                  <h2
                    style={{
                      fontWeight: 600,
                      color: "var(--primary)",
                    }}
                  >
                    {`${translate("trackingOrdine", language)} ${
                      props.shipping.shipmentId
                    } ${
                      props.shipping.transportOrderId
                        ? "| " + props.shipping.transportOrderId
                        : ""
                    }`}
                  </h2>
                  <p
                    style={{
                      color: "var(--primary)",
                    }}
                  >
                    {(props.shipping.orderNumber || props.shipping.reference) &&
                      `${translate("ordine", language)} ${
                        props.shipping.orderNumber || props.shipping.reference
                      }`}
                    {props.shipping?.serviceCode !== "R" &&
                      props.shipping.senderAddress?.companyName &&
                      ` ${translate("da", language)} ${
                        props.shipping.senderAddress.companyName
                      }`}
                  </p>
                  {!!customMessage && (
                    <CustomMessageCard customMessage={customMessage} />
                  )}
                  <div className="tracking-card-container">
                    <div className="card-section">
                      {!!isEstimatedDeliveryDateVisible() && (
                        <EstimatedDeliveryDateCard
                          guestKey={key}
                          shipping={props.shipping}
                          loading={props.loading}
                          isShippingDelivering={isShippingDelivering()}
                          isShippingDelivered={isShippingDelivered()}
                          language={language}
                        />
                      )}
                      {isSchedulingAvailable() && (
                        <SchedulingCard
                          guestKey={key}
                          shipping={props.shipping}
                          loading={props.loading}
                          isShippingDelivering={isShippingDelivering()}
                          isShippingDelivered={isShippingDelivered()}
                          language={language}
                        />
                      )}
                      <PudoCard
                        shipping={props.shipping}
                        pudos={pudos}
                        loading={props.loading}
                        isSchedulingAvailable={isSchedulingAvailable()}
                        isShippingDelivering={isShippingDelivering()}
                      />
                      {isReturnAvailable() && <ReturnCard guestKey={key} />}
                    </div>
                    <TrackingSection
                      trackingEvents={groupEventsByState(
                        props.shipping.events,
                        props.shipping.provisionModeId
                      )}
                      serviceCode={props.shipping.serviceCode}
                      storageId={props.shipping.warehouseStorageId}
                      provisionId={props.shipping.provisionModeId}
                      provisionLabel={props.shipping.provisionModeDescription}
                      getKoReason={getKoReason}
                      language={language}
                    />
                  </div>
                  {props.shipping?.pod && (
                    <PodCard
                      shipping={props.shipping}
                      loading={props.loading}
                      isSchedulingAvailable={isSchedulingAvailable()}
                    />
                  )}
                  {props.shipping?.provisionModeDescription && (
                    <ConfirmedProvisionCard shipping={props.shipping} />
                  )}
                  {props.shipping.events[props.shipping.events.length - 1]
                    .eventCode === 35 &&
                    !props.shipping?.provisionModeDescription && (
                      <PendingProvisionCard shipping={props.shipping} />
                    )}
                  {!!shippingIsCanceled && (
                    <StoppedShippingReasonCard shipping={props.shipping} />
                  )}
                </div>
              )}
            </>
          )}

          <div className="mobile-buttons-container">
            {isAssistanceAvailable() && !hasChatWidget && (
              <HelpSection
                textColor="var(--primary)"
                title="assistenzaClienti"
                body="assistenza"
                handleClick={() => setIsPopupOpen("contact")}
                buttonLabel="contattaci"
                backgroundColor="#fff"
              />
            )}
            {isWhatsappAvailable && (
              <HelpSection
                backgroundColorBtn="var(--bgSecondaryBtnColor)"
                textColorBtn="var(--textSecondaryBtnColor)"
                textColor="var(--textInSecondaryColor)"
                title="whatsapp"
                body={
                  !verificationComplete
                    ? "inserisciNumero"
                    : "inserisciNumeroInterrompi"
                }
                handleClick={() => setIsPopupOpen("whatsapp")}
                buttonLabel={
                  !verificationComplete
                    ? "attivaServizio"
                    : "interrompiAggiornamento"
                }
              />
            )}
          </div>
          <MobileFooter />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.language.language,
  pudoSetted: state.shippings.pudoSetted,
  shipping: state.shippings.data,
  loading: state.shippings.loading,
  error: state.shippings.error,
});

export default connect(mapStateToProps, {
  setReduxLanguage,
  sendMessage,
})(TrackingPage);
