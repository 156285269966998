import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BackBtn, Button } from "../../shared/Buttons";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import SideSection from "../../components/SideSection/SideSection";
import { Link, useLocation } from "react-router-dom";
import { Text } from "../../assets/lemmata";
import Calendar from "../../components/Calendar/Calendar";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { Popup } from "../../components/Popups/Popup";
import moment from "moment";
import { setReduxLanguage } from "../../reducers/language";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DayPlannerPage = (props) => {
  const id = useQuery().get("id") || localStorage.getItem("id");
  const key = useQuery().get("key") || localStorage.getItem("key");
  // const hasBackPage = props.history?.action === "PUSH";
  const [isPopupOpen, setPopupOpen] = useState(false);
  // const [language, setLanguage] = useState(
  //   localStorage.getItem("lan") || navigator.language.toLowerCase()
  // );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (props.booking === "success") {
  //     setPopupOpen(true);
  //   }
  // }, [props.booking]);

  return (
    <div className="main-container">
      {isPopupOpen && (
        <Popup cardAlignItems="center" cardWidth="35%">
          <Text style={{ fontSize: 24 }} label="PianificaConsegnaSuccesso" />{" "}
          <Button handleclick={() => props.history.push("/")}>
            <Text style={{ color: "#fff" }} label="homepage" />
          </Button>
        </Popup>
      )}
      <SideSection />
      <div className="right-container" style={{ backgroundColor: "#fff" }}>
        <MobileHeader backBtn />
        <div className="flex-row title-container">
          <div className="desktop-btn">
            <BackBtn>
              <Link to={`/`} className="flex-row-center">
                <ArrowLeft width={24} height={32} />
              </Link>
            </BackBtn>
          </div>
          <div className="title-style padding-desktop">
            <Text label="pianificaConsegna" />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center " }}>
          <Calendar trackingId={id} key={key} setPopupOpen={setPopupOpen} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  booking: state.bookings.data,
  slots: state.slots.data,
  loading: state.slots.loading,
  errorSlots: state.slots.error,
  error: state.bookings.error,
});

export default connect(mapStateToProps, { setReduxLanguage })(DayPlannerPage);
