import React, { Component } from "react";
import styled from "styled-components";

import { InputSelect } from "./input-select/InputSelect";

const StyledSelect = styled.select`
  padding: ${(props) => props.padding || "16px 24px"};
  font-size: 16px;
  width: ${(props) => props.width || "100%"};
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) => props.border || "none"};
  border-radius: 50px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 12px 0;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

export class SelectPage extends Component {
  render() {
    return (
      <InputSelect
        input
        errorTrigger={this.props.errorTrigger}
        errorMessage={this.props.errorMessage}
        disabled={this.props.disabled}
        value={this.props.value}
        handleChangeInput={this.props.handleChangeInput}
        target={this.props.target}
        placeholder=""
        options={this.props.options
          .sort((a, b) => (a > b ? 1 : -1))
          .map((option, i) => {
            return {
              value: option,
              label: option,
            };
          })}
      />
    );
  }
}

//      <InputSelect
//   disabled={this.props.disabled}
//   onChange={(event) =>
//     this.props.handleChangeInput(this.props.target, event.target.value)
//   }
//   className="custom-select"
//   value={this.props.value}
// >
//   {this.props.options
//     .sort((a, b) => (a > b ? 1 : -1))
//     .map((option, i) => (
//       <option
//         key={`option_${this.props.target}_${option}_${i}`}
//         value={option}
//       >
//         {option}
//       </option>
//     ))}
// </InputSelect>
