import React from "react";
import styled from "styled-components";

const RatingSection = ({ selectedRate, setSelectedRate }) => {
  const ratingScale = [
    { rating: 1, backgroundColor: "red" },
    { rating: 2, backgroundColor: "red" },
    { rating: 3, backgroundColor: "red" },
    { rating: 4, backgroundColor: "red" },
    { rating: 5, backgroundColor: "red" },
    { rating: 6, backgroundColor: "orange" },
    { rating: 7, backgroundColor: "orange" },
    { rating: 8, backgroundColor: "green" },
    { rating: 9, backgroundColor: "green" },
    { rating: 10, backgroundColor: "green" },
  ];
  const RatingSquare = styled.div`
    width: ${(props) => (props.selected ? "48px" : "48px")};
    height: ${(props) => (props.selected ? "48px" : "48px")};
    margin: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    cursor: pointer;
    border-radius: 6px;
    opacity: ${(props) => (props.selected || !selectedRate ? "1" : "0.3")};
    /*   font-size: ${(props) => (props.selected ? "1.5rem" : "1rem")}; */
    /*     border: ${(props) =>
      props.selected ? "4px solid var(--primary)" : "none"}; */
    &:hover {
      background-color: #ff0000;
      opacity: 0.8;
    }
    // media query
    @media screen and (max-width: 1100px) {
      width: ${(props) => (props.selected ? "40px" : "40px")};
      height: ${(props) => (props.selected ? "40px" : "40px")};
      margin: 0 4px;
      font-size: ${(props) => (props.selected ? "1rem" : "1rem")};
    }
    @media screen and (max-width: 480px) {
      width: ${(props) => (props.selected ? "32px" : "32px")};
      height: ${(props) => (props.selected ? "32px" : "32px")};
      margin: 0 2px;
      font-size: ${(props) => (props.selected ? "0.8rem" : "0.8rem")};
    }
  `;
  return (
    <div style={{ display: "flex", marginTop: "1rem" }}>
      {ratingScale.map((item, index) => {
        return (
          <RatingSquare
            key={index}
            style={{ backgroundColor: item.backgroundColor }}
            selected={selectedRate === item.rating}
            onClick={() => {
              setSelectedRate(item.rating);
            }}
          >
            {item.rating}
          </RatingSquare>
        );
      })}
    </div>
  );
};
export default RatingSection;
