import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../components/Cards/Cards";
import LanguageSelection from "../../components/LanguageSelection/LanguageSelection";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection from "../../components/SideSection/SideSection";
import { setReduxLanguage } from "../../reducers/language";
import { getShippingLogo, getTrackingData } from "../../reducers/shippings";
import { Input, Select } from "../../shared/Inputs";
import { Text, translate } from "../../assets/lemmata";
import RatingSection from "./components/RatingSection";
import { Button } from "../../shared/Buttons";
import ThankSection from "./components/ThankSection";
import { createNewRate, getRatingOptions } from "../../reducers/ratings";
import { ReactComponent as CaretDown } from "../../assets/icons/caret-down.svg";
import { ReactComponent as Check } from "../../assets/icons/check-circle.svg";

const RatingPage = () => {
  // get shipment id and guestKey from params in URL
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const key = queryParams.get("key");
  const dispatch = useDispatch();

  const ratingOptions = useSelector(({ ratings }) => ratings.ratingOptions);
  const rateSent = useSelector(({ ratings }) => ratings.rateSent);
  const shipping = useSelector(({ shippings }) => shippings.data);
  const reduxLanguage = useSelector(({ language }) => language.language);
  const [filteredOptions, setFilteredOptions] = useState(ratingOptions);
  const [selectedRate, setSelectedRate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [comment, setComment] = useState("");
  const [isAlreadyRated, setIsAlreadyRated] = useState(null);

  const shippingLogo = useSelector(
    ({ shippings }) => shippings.shippingLogo || null
  );
  const [language, setLanguage] = useState(
    localStorage.getItem("lan") || navigator.language.toLowerCase()
  );
  const contractId = null;

  useEffect(() => {
    if (!!id && !!key) {
      dispatch(getTrackingData(id, key, language));
      dispatch(getRatingOptions(id, key, language));
    }
  }, [id, key, language]);

  useEffect(() => {
    if (contractId) {
      dispatch(getShippingLogo(contractId));
    }
  }, []);

  useEffect(() => {
    if (!!reduxLanguage) {
      console.log("reduxLanguage", reduxLanguage);
      setLanguage(reduxLanguage);
    }
  }, [reduxLanguage]);

  useEffect(() => {
    if (!!shipping) {
      setIsAlreadyRated(shipping.rated);
    }
  }, [shipping]);

  useEffect(() => {
    if (selectedRate)
      setFilteredOptions(
        ratingOptions.filter(
          (option) =>
            option.minScore <= selectedRate && option.maxScore >= selectedRate
        )
      );
  }, [selectedRate, language]);

  const selectLanguage = (lan) => {
    setLanguage(lan);
    dispatch(setReduxLanguage(lan));
  };

  const handleSentRate = () => {
    dispatch(
      createNewRate(id, key, {
        score: selectedRate,
        comment: comment || null,
        optionId: selectedOption ? parseInt(selectedOption) : null,
      })
    );
  };
  console.log("language", language);
  return (
    <div className="main-container">
      <SideSection />
      <div className="right-container" style={{}}>
        <MobileHeader />
        <div className="main-header">
          <div className="container-logo">
            {shippingLogo && (
              <img
                className="logo"
                src={`data:${shippingLogo.contentType};base64, ${shippingLogo.content}`}
                alt={shippingLogo.fileName}
              />
            )}
          </div>

          <LanguageSelection selectLanguage={selectLanguage} />
        </div>
        {isAlreadyRated ? (
          <div
            className="flex-column-center"
            style={{ minHeight: "calc(100vh - 240px)" }}
          >
            <Check width={100} />
            <Text
              style={{
                textAlign: "center",
                color: "var(--primary)",
                fontSize: "1.2rem",
              }}
              label="votoEspresso"
            />
          </div>
        ) : (
          <div
            className="flex-row-center"
            style={{ minHeight: "calc(100vh - 240px)" }}
          >
            {!rateSent ? (
              <Card cardAlignItems="center" width="65%">
                <h2 style={{ textAlign: "center", color: "var(--primary)" }}>
                  {translate("rateTitle", language)}
                </h2>
                <p style={{ textAlign: "center", color: "var(--primary)" }}>
                  {translate("rateSubTitle", language)}
                </p>
                <RatingSection
                  selectedRate={selectedRate}
                  setSelectedRate={setSelectedRate}
                  language={language}
                />
                {!!selectedRate && (
                  <>
                    <div className="mt-1" style={{ width: "100%" }}>
                      <h5 style={{ color: "var(--primary)" }}>
                        {translate("rateReason", language)}
                      </h5>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <Select
                          padding="16px 52px 16px 24px"
                          onChange={(e) => {
                            const selectedOptionId =
                              e.target.options[e.target.selectedIndex].id;
                            setSelectedOption(selectedOptionId);
                          }}
                          style={{
                            borderRadius: 50,
                            color: "var(--primary)",
                            position: "relative",
                          }}
                          className="calendar-select mt-05"
                        >
                          <option>{translate("select", language)}</option>
                          {filteredOptions.map(
                            (option, i) =>
                              option && (
                                <option key={`${i}_${language}`} id={option.id}>
                                  {option.locale?.text}
                                </option>
                              )
                          )}
                        </Select>
                      </div>
                      {/* <Input placeholder={translate("select", language)}></Input> */}
                      <div className="mt-2">
                        <Input
                          placeholder={translate(
                            "rateReasonPlaceholder",
                            language
                          )}
                          textarea
                          onChange={(e) => setComment(e.target.value)}
                        ></Input>
                      </div>
                    </div>
                    <Button
                      disabled={!key}
                      handleclick={() => handleSentRate()}
                      style={{ color: "white" }}
                    >
                      {translate("rateButton", language)}
                    </Button>
                  </>
                )}
              </Card>
            ) : (
              <ThankSection language={language} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RatingPage;
