import * as React from "react";

const Logo = (props) => (
  <svg
    id="Livello_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 230 110"
    style={{
      enableBackground: "new 0 0 230 110",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M101.1 21 90.5 50.1 79.9 21H59.4l20.9 57.3L68.7 110h20.6l1.2-3.4 10.3-28.3L121.7 21zM55.4 41.5 47.9 21H38V.5H17.4V21H0l7.5 20.5h9.9v48H38v-48zM160.8 21.3c-6.6-.9-13 .1-18.5 2.5-12.1 5.3-20.5 17.3-20.5 31.4V110h20.5V86.6c4.2 1.8 8.8 2.9 13.7 2.9 20.1 0 36.2-17.3 34-37.9-1.6-15.5-13.9-28.1-29.2-30.3zm-4.9 47.6c-7.6 0-13.7-6.1-13.7-13.7s6.1-13.7 13.7-13.7 13.7 6.1 13.7 13.7-6.1 13.7-13.7 13.7zM199.4 82.8l18.2 6.6L230 55.2 217.5 21l-18.1 6.6 10.1 27.6z" />
  </svg>
);

export default Logo;
