import React, { useEffect, useState } from "react";
import "./MobileHeader.scss";
import { BackBtn } from "../../shared/Buttons";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left-black.svg";
// import { ReactComponent as LogoA2U } from "../../assets/img/a2u-logo.svg";
// import { ReactComponent as TypLogo } from "../../assets/img/typ-logo-white.svg";
import Logo, { logoSize } from "../../assets/Logo";
import moment from "moment";
import { setReduxLanguage } from "../../reducers/language";
import { useDispatch } from "react-redux";

const MobileHeader = (props) => {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(
    localStorage.getItem("lan") || navigator.language.toLowerCase()
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      language.includes("it") ||
      language.includes("en") ||
      language.includes("es") ||
      language.includes("fr") ||
      language.includes("de")
    ) {
      localStorage.setItem("lan", language);
      moment.locale(language);
      dispatch(setReduxLanguage(language));
    } else {
      localStorage.setItem("lan", "en");
      moment.locale("en");
      dispatch(setReduxLanguage("en"));
    }
  }, [language]);

  return (
    <div className="mobile-header">
      <div style={{ flex: 1 }}>
        {props.backBtn && (
          <BackBtn backgroundColor="var(--secondary)">
            <Link to={`/`} className="flex-row-center">
              <ArrowLeft width={24} height={32} />
            </Link>
          </BackBtn>
        )}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo style={{ width: logoSize - 20, fill: "#fff" }} />
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default MobileHeader;
