import moment from "moment";

export const daylist = (date = new Date()) =>
  createCalendar(date).map((day) => moment(day).format("YYYY-MM-DD"));

const addZero = (string) => {
  return String(string).length > 1 ? string : `0${string}`;
};
const createCalendar = (date) => {
  date = moment(date);
  const currentMonth = moment(date).month();
  const days = moment(date).daysInMonth();
  const currentYear = moment(date).year();
  const calendar = [];
  for (let i = 0; i < days; i++) {
    calendar.push(
      moment(`${currentYear}/${currentMonth + 1}/${addZero(i + 1)}`)
    );
  }
  return calendar;
};

const byWeek = (days) => {
  const weeks = days.reduce((acc, curr) => {
    const currentWeek = curr.weeks();
    return {
      ...acc,
      [currentWeek]: [...(!!acc[currentWeek] ? acc[currentWeek] : []), curr],
    };
  }, {});
  return Object.entries(weeks)
    .map((week) => week[1])
    .sort((a, b) => (a[0] > b[0] ? 1 : -1));
};
const fillWeek = (arr, i, weeks) => {
  if (arr.length < 7) {
    const currentWeek = moment(arr[0]).weeks();
    const daysGeneral = [0, 1, 2, 3, 4, 5, 6];
    const daysCurrent = arr.map((day) => moment(day).day());
    const daysMissing = daysGeneral.filter(
      (day) => !daysCurrent.some((d) => d === day)
    );
    const isLastWeekOfMonth = i === weeks.length - 1;
    const isFirstWeekOfMonth = i === 0;
    return [
      ...daysMissing.map((day, idx) => {
        if (isFirstWeekOfMonth) {
          const first = arr.sort((a, b) => (a > b ? 1 : -1))[0];
          return moment(first).subtract(idx + 1, "d");
        }
        if (isLastWeekOfMonth) {
          const last = arr.sort((a, b) => (a > b ? -1 : 1))[0];
          return moment(last).add(idx + 1, "d");
        }
        return moment(`${currentWeek} ${day === 0 ? 6 : day - 1}`, "ww e");
      }),
      ...arr,
    ].sort((a, b) => (a < b ? -1 : 1));
  }
  return arr;
};
export const formatCalendar = (date) =>
  byWeek(createCalendar(moment(date))).map(fillWeek);
