// import { CaretDown, X } from "phosphor-react";
import { useState, useEffect } from "react";
import useComponentVisible from "../../utils/useComponentVisible";
import "./InputSelect.scss";

export const InputSelect = (props) => {
  const [zipCode, setZipCode] = useState(props.value);
  const { ref, isComponentVisible, setIsComponentVisible, toggling } =
    useComponentVisible(false);

  const handleChange = (option) => {
    props.handleChangeInput(props.target, option.value);
    setIsComponentVisible(false);
  };

  // useEffect(() => {
  //   setZipCode(props.value);
  // }, []);

  // const handleReset = (event) => {
  //   event.stopPropagation();
  //   props.handleChangeInput(
  //     { value: "", label: props.placeholder },
  //     props.target
  //   );
  // };
  return (
    <div ref={ref} className="input-select-component">
      <div className={`placeholder ${isComponentVisible ? "active" : ""}`}>
        <input
          disabled={props.disabled}
          onClick={toggling}
          type="text"
          value={props.value}
          onChange={(event) => {
            setZipCode(event.target.value);
            props.handleChangeInput(props.target, event.target.value);
          }}
        />

        {/* {props.value.value ? (
          <X onClick={handleReset} className="icon-select" />
          ) : (
            <CaretDown
            weight="bold"
            className={`icon-select ${isComponentVisible ? "active" : ""}`}
            />
          )} */}
      </div>

      {props.errorTrigger && (
        <p
          style={{
            color: "crimson",
            fontSize: 12,
            marginBottom: 0,
            fontWeight: 500,
          }}
        >
          {props.errorMessage}
        </p>
      )}
      {isComponentVisible && (
        <div className="options-container">
          {props.options
            .filter((option) => option.value.includes(zipCode))
            .slice(0, 10)
            .map((option, i) => (
              <div
                key={i}
                onClick={() => {
                  setZipCode(option.value);
                  handleChange(option);
                }}
                className="option"
              >
                {option.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
