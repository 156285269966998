import React from "react";
import { Text } from "../../../assets/lemmata";
import { Card } from "../../../components/Cards/Cards";
import moment from "moment";

const StoppedShippingReasonCard = ({ shipping }) => {
  return (
    <>
      <Card
        className="card"
        cardTitle={<Text label="motivoAnnullamento" />}
        cardJustifyContent="space-between"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "var(--primary)",
              fontSize: "0.9rem",
            }}
          >
            {
              shipping.events.filter((event) => event.eventCode === 96)[0]
                ?.eventName
            }
          </div>
          <div
            style={{
              color: "var(--primary)",
              fontSize: "0.9rem",
            }}
          >
            {moment(
              shipping.events.filter((event) => event.eventCode === 96)[0]
                ?.dateTime
            ).format("DD/MM/YYYY - HH:mm")}
          </div>
        </div>
      </Card>
    </>
  );
};

export default StoppedShippingReasonCard;
