import axios from "axios";
import api from "../server/api";

const SEND_MESSAGE_REQUEST = "TYP/SEND_MESSAGE_REQUEST";
const SEND_MESSAGE_SUCCESS = "TYP/SEND_MESSAGE_SUCCESS";
const SEND_MESSAGE_FAILURE = "TYP/SEND_MESSAGE_FAILURE";

const REQUEST_OTP_REQUEST = "TYP/REQUEST_OTP_REQUEST";
const REQUEST_OTP_SUCCESS = "TYP/REQUEST_OTP_SUCCESS";
const REQUEST_OTP_FAILURE = "TYP/REQUEST_OTP_FAILURE";

const ENABLE_OTP_REQUEST = "TYP/ENABLE_OTP_REQUEST";
const ENABLE_OTP_SUCCESS = "TYP/ENABLE_OTP_SUCCESS";
const ENABLE_OTP_FAILURE = "TYP/ENABLE_OTP_FAILURE";

// const ACTIVATE_NOTIFICATION_REQUEST = "TYP/ACTIVATE_NOTIFICATION_REQUEST";
// const ACTIVATE_NOTIFICATION_SUCCESS = "TYP/ACTIVATE_NOTIFICATION_SUCCESS";
// const ACTIVATE_NOTIFICATION_FAILURE = "TYP/ACTIVATE_NOTIFICATION_FAILURE";

const initialState = {
  loading: false,
  error: false,
  errorRequest: false,
  errorVerification: false,
  data: "",
  requestId: null,
};

export default function reducerMessages(state = initialState, action) {
  switch (action.type) {
    case SEND_MESSAGE_REQUEST:
      return { ...state, loading: true, error: false };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SEND_MESSAGE_FAILURE:
      return { ...state, loading: false, data: "", error: action.payload };
    case REQUEST_OTP_REQUEST:
      return { ...state, loading: true, errorRequest: false };
    case REQUEST_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        requestId: action.payload,
      };
    case REQUEST_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        errorRequest: action.payload,
      };
    case ENABLE_OTP_REQUEST:
      return { ...state, loading: true, error: false };
    case ENABLE_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case ENABLE_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        data: "",
        errorVerification: action.payload,
      };

    default:
      return state;
  }
}

export const sendMessage = (messageData, id) => {
  return async (dispatch) => {
    dispatch({
      type: SEND_MESSAGE_REQUEST,
    });

    try {
      const { url, method, headers, params, data } = api.sendMessage(
        messageData,
        id
      );

      const message = await axios({
        url,
        method,
        headers,
        data,
        params,
      });

      dispatch({
        type: SEND_MESSAGE_SUCCESS,
        payload: "success",
      });

      return "success";
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: SEND_MESSAGE_FAILURE,
        payload: error,
      });
    }
  };
};
export const requestOtp = (number, shipmentId, optInEnabled) => {
  return async (dispatch) => {
    dispatch({
      type: REQUEST_OTP_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.requestOtp(
        number,
        shipmentId,
        optInEnabled
      );

      const request = await axios({
        url,
        method,
        headers,
        data,
      });

      if (request) {
        dispatch({
          type: REQUEST_OTP_SUCCESS,
          payload: request.data.id,
        });

        return "success";
      }
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: REQUEST_OTP_FAILURE,
        payload: error.response,
      });
    }
  };
};
export const enableOtp = (pin, id, optInEnabled) => {
  return async (dispatch) => {
    dispatch({
      type: ENABLE_OTP_REQUEST,
    });

    try {
      const { url, method, headers, data } = api.enableOtp(
        pin,
        id,
        optInEnabled
      );

      const request = await axios({
        url,
        method,
        headers,
        data,
      });

      if (request) {
        dispatch({
          type: ENABLE_OTP_SUCCESS,
          payload: "success",
        });

        return "success";
      }
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: ENABLE_OTP_FAILURE,
        payload: error.response.data,
      });
    }
  };
};

export const activateNotifications = async (name, number) => {
  try {
    const { url, method, headers, data } = api.activateNotifications(
      name,
      number
    );

    const message = await axios({
      url,
      method,
      headers,
      data,
    });

    return message.data;
  } catch (error) {
    console.log("error", error);
    console.log("error", error.response);
    return error;
  }
};
