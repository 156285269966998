import React from "react";
import { Text } from "../../../assets/lemmata";
import { Card } from "../../../components/Cards/Cards";
import { ReactComponent as Envelope } from "../../../assets/icons/mail.svg";

const CustomMessageCard = ({ customMessage }) => {
  return (
    <>
      <Card
        className="card custom-message-card"
        cardDirection="row"
        // cardTitle={<Text label="messaggioCustom" />}
        cardJustifyContent="space-between"
        backgroundColor="var(--toggleSectionIconColor)"
        cardAlignItems="center"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",

            // justifyContent: "space-between",
          }}
          className="custom-message-title"
        >
          <Envelope
            style={{
              width: 36,
              height: 36,
              fill: "var(--envelopeColor)",
              marginRight: "0.5rem",
            }}
          />
          <Text
            className="custom-message"
            label="messageForYou"
            style={{
              color: "var(--envelopeColor)",
              fontSize: "1.2rem",
              textAlign: "center",
              /*     maxWidth: "60%", */
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              color: "#fff",
              fontSize: "0.9rem",
              wordBreak: "break-word",
              textAlign: "center",
            }}
          >
            <div
              className="custom-message"
              dangerouslySetInnerHTML={{
                __html: customMessage.note,
              }}
            />
            {/* {customMessage.note} */}
          </div>
        </div>
      </Card>
    </>
  );
};

export default CustomMessageCard;
