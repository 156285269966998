import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

class PodMap extends Component {
  render() {
    return (
      <LoadScript googleMapsApiKey="AIzaSyD60SmXreBL_59HmY5l6sYDnFG0zbe-Zkg">
        <GoogleMap
          mapContainerStyle={{ width: this.props.width, height: "100%" }}
          center={{
            lat: this.props.lat !== "null" ? this.props.lat : 45,
            lng: this.props.lon !== "null" ? this.props.lon : 9,
          }}
          zoom={15}
        >
          <Marker position={{ lat: this.props.lat, lng: this.props.lon }} />
          <></>
        </GoogleMap>
      </LoadScript>
    );
  }
}

export default PodMap;
