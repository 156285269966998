import LogoA2U from "../assets/a2u/Logo";
import LogoTyp from "../assets/typ/Logo";

const logos = {
  a2u: LogoA2U,
  a2ufr: LogoA2U,
  typ: LogoTyp,
};

export default logos[process.env.REACT_APP_TARGET];

const logoSizes = {
  a2u: 100,
  a2ufr: 100,
  typ: 80,
};

export const logoSize = logoSizes[process.env.REACT_APP_TARGET];
