import React from "react";
import { Link } from "react-router-dom";
import { Text } from "../../../assets/lemmata";
import { Card } from "../../../components/Cards/Cards";
import { ButtonLink } from "../../../shared/Buttons";
import { ReactComponent as Package } from "../../../assets/icons/packageBlu.svg";

const ReturnCard = ({ guestKey }) => {
  return (
    <>
      <Card className="card">
        <div
          className="flex-row mb-05"
          style={{ paddingBottom: 8, alignItems: "center" }}
        >
          <Package width={28} height={28} style={{ marginRight: 8 }} />
          <h2>
            <Text
              style={{
                fontWeight: 500,
                fontSize: "0.8em",
                color: "var(--primary)",
              }}
              label="infoReso"
            />
          </h2>
        </div>
        <div className="flex-row-center">
          <Link
            className="link-style"
            to={
              guestKey
                ? {
                    pathname: "/reso",
                  }
                : {
                    pathname: "/richiesta-link",
                    state: {
                      action: "RETURN",
                      title: "richiestaReso",
                    },
                  }
            }
          >
            <ButtonLink
              backgroundColor="var(--primary)"
              border="1px solid var(--primary)"
              marginTop={"8px"}
            >
              <Text style={{ color: "#fff" }} label="gestisciReso" />
            </ButtonLink>
          </Link>
        </div>
      </Card>
    </>
  );
};

export default ReturnCard;
