import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { Text, translate } from "../../assets/lemmata";
import { Card } from "../../components/Cards/Cards";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection from "../../components/SideSection/SideSection";
import { BackBtn, Button } from "../../shared/Buttons";
import { Input } from "../../shared/Inputs";
import { ReactComponent as Mail } from "../../assets/icons/mail.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone-solid.svg";
import { elevatePrivileges } from "../../reducers/account";

const viewportHeight = window.innerHeight;

const LinkRequest = () => {
  const dispatch = useDispatch();
  const lan = localStorage.getItem("lan") || "it";
  const location = useLocation();
  const id = localStorage.getItem("id");
  const action = location?.state?.action;
  const [prefix, setPrefix] = useState("+39");

  const shipping = useSelector(({ shippings }) => shippings.data);
  const account = useSelector(({ account }) => account.data);
  // const loading = useSelector(({account}) => account.loading);
  const error = useSelector(({ account }) => account.error);

  const inputList = [
    {
      placeholder: translate("email", lan),
      target: "email",
      type: "email",
      icon: <Mail width={24} height={24} />,
    },
    {
      placeholder: translate("phoneNumber", lan),
      target: "mobile",
      type: "tel",
      icon: <Phone width={24} height={24} />,
      prefixSelect: true,
      prefixSelectMarginTop: 10,
    },
  ];
  const [state, setState] = useState({
    shipment: {
      id: shipping?.shipmentId || id,
      // reference: shipping?.reference || null,
      // contractCode: shipping?.contractCode || null,
    },
    action: action,
  });

  const handleChange = (target, value) => {
    setState((prevState) => ({
      ...prevState,
      [target]: value,
    }));
  };

  const _isDisabled = () => {
    const inputStatus = inputList.map(
      (input) => !![input.target] && state[input.target] !== ""
    );
    const inputAreValid = inputStatus.some((input) => !!input);
    return !inputAreValid;
  };

  const handleclick = () => {
    dispatch(
      elevatePrivileges({
        ...state,
        mobile: state.mobile ? prefix + state.mobile : null,
      })
    );
  };

  const handlePrefixSelection = (value) => {
    setPrefix(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <SideSection />
      <div
        className="right-container-noscroll"
        style={{ backgroundColor: "#fff", height: viewportHeight }}
      >
        <MobileHeader backBtn />
        <div style={{ flexGrow: 1 }}>
          <div className="flex-row title-container">
            <div className="desktop-btn">
              <BackBtn>
                <Link to="/" className="flex-row-center">
                  <ArrowLeft width={24} height={32} />
                </Link>
              </BackBtn>
            </div>
            <div className="title-style">
              <Text label={location?.state?.title} />
            </div>
          </div>
          <div
            style={{
              padding: "0 5% 24px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card
              half
              paddingCard="4px 10%"
              paddingCardMobile="4px 0px"
              cardTitle={<Text label="inserisciMail" />}
              fontSize="1rem"
              titleAlign="center"
            >
              {inputList.map((input, i) => (
                <Input
                  key={`key_${input.target}`}
                  target={input.target}
                  placeholder={input.placeholder}
                  handleChange={handleChange}
                  type={input.type}
                  icon={input.icon}
                  prefixSelect={input.prefixSelect}
                  prefixSelectHandleChange={handlePrefixSelection}
                  prefixSelectMarginTop={input.prefixSelectMarginTop}
                />
              ))}
              <Text
                style={{
                  fontSize: "0.6rem",
                  textAlign: "center",
                  alignSelf: "center",
                  color: "var(--primary)",
                  marginTop: 8,
                  marginBottom: 8,
                }}
                label="disclaimerUtilizzoDati"
              />
            </Card>

            {error && (
              <Text
                label="mailNonCorrispondente"
                style={{ fontSize: 12, fontWeight: 500, color: "crimson" }}
              />
            )}
            {account === "success" && (
              <Text
                label="linkInviato"
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "forestgreen",
                  marginBottom: 4,
                }}
              />
            )}
            <div className="flex-row-center">
              <Button
                marginTop="4px"
                backgroundColor="var(--primary)"
                color="#fff"
                border="1px solid var(--primary)"
                handleclick={() => handleclick()}
                disabled={_isDisabled()}
              >
                <Text style={{ fontWeight: 500 }} label="richiedi" />
              </Button>
            </div>
          </div>
        </div>
        <MobileFooter />
      </div>
    </div>
  );
};

export default LinkRequest;
