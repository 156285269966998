import React from "react";
import styled from "styled-components";
import { ReactComponent as Check } from "../assets/icons/check-circle.svg";
import { ReactComponent as BlackCheck } from "../assets/icons/check-circle-black.svg";

const VerticalLine = styled.div`
  width: ${(props) => props.width || "4px"};
  height: ${(props) => props.height || "24px"};
  position: absolute;
  bottom: 0;
  left: ${(props) => props.left || "calc(50% -2px)"};
  background-color: ${(props) => props.backgroundColor || "var(--secondary)"};
`;

export const CheckCircle = () => {
  return (
    <div
      style={{
        position: "relative",
        flex: 1,
        height: 100,
      }}
    >
      <VerticalLine backgroundColor="#000" width="3px" />
      <BlackCheck width="32" height="32" style={{ marginTop: -4 }} />
    </div>
  );
};
export const LightCheckCircle = () => {
  return (
    <div
      // style={{
      //   position: "relative",
      //   width: 32,
      //   height: 100,
      //   flex: 1,
      // }}
      style={{
        position: "relative",
        width: 32,
        height: 80,
      }}
    >
      <VerticalLine left="14px" width="3px" height="56px" />
      <Check width="32" height="32" style={{ marginTop: -4 }} />
    </div>
  );
};

export const VerticalLineTrack = () => {
  return <VerticalLine backgroundColor="var(--primary)" width="2px" />;
};
