import { ReactComponent as ArrowLeft } from "../assets/icons/caret-left-blu.svg";
import { ReactComponent as ArrowRight } from "../assets/icons/caret-right-blu.svg";

export const CalendarArrowLeft = (props) => {
  return (
    <ArrowLeft
      {...props}
      style={{ cursor: "pointer" }}
      width={28}
      height={28}
      className={`${props.leftDisabled ? "disabled" : ""}`}
      weight="bold"
      onClick={props.handleclick && (() => props.handleclick())}
    />
  );
};

export const CalendarArrowLeftDisabled = (props) => {
  return (
    <ArrowLeft
      {...props}
      style={{ opacity: "0.2" }}
      width={28}
      height={28}
      weight="bold"
    />
  );
};

export const CalendarArrowRight = (props) => {
  return (
    <ArrowRight
      {...props}
      style={{ cursor: "pointer" }}
      width={28}
      height={28}
      weight="bold"
      onClick={() => props.handleclick()}
    />
  );
};
