import React from "react";
import { Text } from "../../../assets/lemmata";
import { Card } from "../../../components/Cards/Cards";
import PodMap from "../../../components/Map/Map";
import { Spinner } from "../../../shared/Spinner";
import moment from "moment";

const PodCard = ({ shipping, loading, isSchedulingAvailable }) => {
  // check is order is delivered at pudo
  const deliveryAtPudoEvent = shipping?.events.find(
    (event) => event.eventCode === 83
  );

  return (
    <>
      <Card
        minHeight={isSchedulingAvailable && "240px"}
        half
        cardTitle={<Text label="pod" />}
        /* width="66%" */
        width="100%"
      >
        {loading ? (
          <Spinner />
        ) : (
          shipping?.pod && (
            <div className="pod-container">
              <div className="pod-map">
                <PodMap
                  lat={shipping?.pod?.latitude}
                  lon={shipping?.pod?.longitude}
                />
                {shipping?.pod?.latitude === null && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      backgroundColor: "white",
                      opacity: 0.8,
                      height: 240,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        fontWeight: 500,
                      }}
                      label="posizioneNonDisponibile"
                    />
                  </div>
                )}
              </div>
              <div className="pod-data">
                <div>
                  <Text style={{ fontWeight: 600 }} label={"dateTime"} />
                  {moment(shipping?.pod?.dateTime).format("DD/MM/YYYY HH:mm")}
                </div>
                <div>
                  <Text
                    style={{ marginTop: 12, fontWeight: 600 }}
                    label={"consigneeName"}
                  />
                  {shipping?.pod?.consigneeName}
                </div>{" "}
                {shipping?.pod?.reservedAcceptance && (
                  <>
                    <div>
                      <Text
                        style={{
                          marginTop: 12,
                          fontWeight: 600,
                        }}
                        label={"reservedAcceptance"}
                      />
                      {shipping?.pod?.reservedAcceptance ? (
                        <Text label="Si" />
                      ) : (
                        "No"
                      )}
                    </div>
                    <div>
                      <Text
                        style={{
                          marginTop: 12,
                          fontWeight: 600,
                        }}
                        label={"reservedAcceptanceMessage"}
                      />
                      {shipping?.pod?.reservedAcceptanceMessage}
                    </div>
                  </>
                )}
                {/* <div style={{ paddingLeft: 16 }}></div> */}
                <Text
                  label={"signature"}
                  style={{
                    fontWeight: 600,
                    paddingTop: 12,
                  }}
                />
                <img
                  style={{ maxWidth: "100%" }}
                  src={`data:image/${shipping?.pod?.signature?.contentType};base64, ${shipping?.pod?.signature?.content}`}
                  alt={shipping?.pod?.signature?.fileName}
                />
                {deliveryAtPudoEvent && (
                  <div className="flex-column-mobile">
                    <Text
                      style={{
                        fontWeight: 600,
                        color: "var(--primary)",
                      }}
                      label={"consegnatoPudo"}
                    />
                    <div
                      className="only-desktop-lg"
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      -
                    </div>
                    <div
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      {moment(deliveryAtPudoEvent?.dateTime).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </Card>
    </>
  );
};

export default PodCard;
