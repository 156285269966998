import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const translate = (id, lan) => {
  if (lan.includes("en")) {
    lan = "en";
  }
  if (lan.includes("it")) {
    lan = "it";
  }
  if (lan.includes("es")) {
    lan = "es";
  }
  if (lan.includes("fr")) {
    lan = "fr";
  }
  if (lan.includes("de")) {
    lan = "de";
  }
  const text = texts.find((text) => text.id === id);
  const translation = text && text[lan];
  return translation || `${id}[daTradurre]`;
};

const texts = [
  {
    id: "trackingOrdine",
    it: "Tracking ordine",
    en: "Tracking order",
    es: "Seguimiento de pedido",
    fr: "Suivi de commande",
    de: "Verfolgung der Bestellung",
  },
  {
    id: "trackingNumero",
    it: "Tracking numero",
    en: "Tracking number",
    es: "Número de seguimiento",
    fr: "Numéro de suivi",
    de: "Tracking-Nummer",
  },
  {
    id: "indirizzo",
    it: "Indirizzo di consegna",
    en: "Delivery address",
    es: "Dirección de entrega",
    fr: "Adresse",
    de: "Adresse",
  },
  {
    id: "TYP",
    it: "TYP",
    en: "TYP",
    es: "TYP",
    fr: "TYP",
    de: "TYP",
  },
  {
    id: "spedizioneNonTrovata",
    it: "Ordine non trovato",
    en: "Order not found",
    es: "Pedido no encontrado",
    fr: "Commande non trouvée",
    de: "Bestellung nicht gefunden",
  },
  {
    id: "prenotazione",
    it: "Prenotazione",
    en: "Booking",
    es: "Reserva",
    fr: "Réservation",
    de: "Reservierung",
  },
  {
    id: "modalitaConsegna",
    it: "Modalità di consegna",
    en: "Delivery Methods",
    es: "Método de entrega",
    fr: "Méthodes de livraison",
    de: "Methoden der Lieferung",
  },
  {
    id: "pianifica",
    it: "Pianifica",
    en: "Plan",
    es: "Programe",
    fr: "Planifier",
    de: "Planen",
  },
  {
    id: "pianificaConsegna",
    it: "Pianifica la tua consegna",
    en: "Plan your delivery",
    es: "Programe su entrega",
    fr: "Planifiez votre livraison",
    de: "Planen Sie Ihre Lieferung",
  },
  {
    id: "prenotazionePersonalizzata",
    it: "Vuoi modificare la tua prenotazione?",
    en: "Do you want to customize your booking?",
    es: "¿Quieres elegir el día y franja horaria de su entrega?",
    fr: "Voulez-vous personnaliser votre réservation?",
    de: "Möchten Sie Ihre Reservierung personalisieren?",
  },
  {
    id: "prenotazionePrevista",
    it: "Prenotazione prevista per il",
    en: "Booking planned on",
    es: "Entrega prevista para el",
    fr: "Réservation planifiée le",
    de: "Reservierung geplant am",
  },
  {
    id: "consegnaEffettuata",
    it: "Consegna effettuata il",
    en: "Shipment delivered on",
    es: "Entrega efectuada el",
    fr: "Envoi livré le",
    de: "Sendung geliefert am",
  },
  {
    id: "gestisciReso",
    it: "Gestisci il tuo reso",
    en: "Manage your return",
    es: "Gestione su devolución",
    fr: "Gérer le retour",
    de: "Verwalten Sie die Rücksendung",
  },
  {
    id: "inserisciOrdine",
    it: "Ricevi gratuitamente sul tuo numero WhatsApp notifiche e aggiornamenti sullo stato dei tuoi ordini.",
    en: "Receive free notifications and updates on the status of your orders on your WhatsApp number.",
    es: "Reciba gratuitamente en su número whatsapp notificaciones y actualizaciones sobre el estado de los pedido.",
    fr: "Recevez gratuitement sur votre numéro WhatsApp des notifications et des mises à jour sur l'état de vos commandes.",
    de: "Erhalten Sie kostenlos Benachrichtigungen und Updates zum Status Ihrer Bestellungen auf Ihrer WhatsApp-Nummer.",
  },
  {
    id: "gestioneReso",
    it: "Gestione reso",
    en: "Manage return",
    es: "Gestione su devolución",
    fr: "Gérer le retour",
    de: "Verwalten Sie die Rücksendung",
  },
  {
    id: "indirizzoRitiro",
    it: "Indirizzo ritiro",
    en: "Pickup address",
    es: "Dirección de recogida",
    fr: "Adresse d'enlèvement",
    de: "Adresse der Abholung",
  },
  {
    id: "dataRitiro",
    it: "Data ritiro",
    en: "Pickup date",
    es: "Fecha de recogida",
    fr: "Date d'enlèvement",
    de: "Datum der Abholung",
  },
  {
    id: "richiestaPianifica",
    it: "Richiesta ritiro pianificato",
    en: "Scheduled delivery request",
    es: "Solicitud de entrega programada",
    fr: "Demande de livraison programmée",
    de: "Anfrage für eine geplante Lieferung",
  },
  {
    id: "numeroWhatsapp",
    it: "NUMERO WHATSAPP",
    en: "WHATSAPP NUMBER",
    es: "NÚMERO WHATSAPP",
    fr: "NUMÉRO WHATSAPP",
    de: "WHATSAPP NUMMER",
  },
  {
    id: "attivaServizio",
    it: "ATTIVA SERVIZIO",
    en: "ACTIVATE SERVICE",
    es: "ACTIVE EL SERVICIO",
    fr: "ACTIVER LE SERVICE",
    de: "DEN DIENST AKTIVIEREN",
  },
  {
    id: "infoReso",
    it: "Informazioni sui cambi e resi",
    en: "Change and return informations",
    es: "Información sobre cambios y devoluciones",
    fr: "Informations sur les changements et les retours",
    de: "Informationen über Änderungen und Rücksendungen",
  },
  {
    id: "inserisciNumero",
    it: "Ricevi gratuitamente sul tuo numero WhatsApp notifiche e aggiornamenti sullo stato delle tue spedizioni.",
    en: "Receive free notifications and updates on the status of your shipments on your WhatsApp number.",
    es: "Reciba notificaciones gratuitas sobre el estado de sus envíos en su número de WhatsApp.",
    fr: "Recevez gratuitement des notifications et des mises à jour sur l'état de vos commandes sur votre numéro WhatsApp.",
    de: "Erhalte kostenlose Benachrichtigungen und Updates über den Status deiner Bestellungen auf deiner WhatsApp-Nummer.",
  },
  {
    id: "inserisciNumeroInterrompi",
    it: "Inserisci il tuo numero di Whatsapp per interrompere gli aggiornamenti sullo stato della tua spedizione",
    en: "Enter your Whatsapp number to stop updates on the status of your shipping",
    es: "Introduzca su número de Whatsapp para detener las actualizaciones sobre el estado de tu envío",
    fr: "Entrez votre numéro de commande pour suivre votre colis",
    de: "Geben Sie Ihre Whatsapp-Nummer ein, um die Aktualisierungen zum Status Ihrer Bestellung zu stoppen.",
  },
  {
    id: "scegliOrario",
    it: "Scegli l'orario",
    en: "Choose the time slot",
    es: "Eliga una franja horaria",
    fr: "Choisissez le créneau horaire",
    de: "Wählen Sie das Zeitfenster aus.",
  },
  {
    id: "restituzioneArticoli",
    it: "Restituzione Articoli",
    en: "Return goods",
    es: "Devolución del pedido",
    fr: "Retour des marchandises",
    de: "Rückgabe von Waren",
  },
  {
    id: "motivazioneRichiesta",
    it: "Motivazione della richiesta",
    en: "Reason for your request",
    es: "Motivo de la solicitud",
    fr: "Motif de votre demande",
    de: "Grund für Ihre Anfrage",
  },
  {
    id: "pianificaReso",
    it: "Pianifica reso",
    en: "Plan Return",
    es: "Organice su devolución",
    fr: "Planifier le retour",
    de: "Rücksendung planen",
  },
  {
    id: "inviaFeedback",
    it: "Invia feedback e pianifica reso",
    en: "Send your feedback and plan the return",
    es: "Envíe su feedback y planifique una devolución",
    fr: "Envoyez votre avis et planifiez le retour",
    de: "Senden Sie Ihr Feedback und planen Sie die Rücksendung",
  },
  {
    id: "destinazione",
    it: "Destinazione:",
    en: "Destination:",
    es: "Destino:",
    fr: "Destination:",
    de: "Zielort:",
  },
  {
    id: "consegnaSuccesso",
    it: "Consegna avvenuta con successo",
    en: "Delivery was successful",
    es: "Entrega realizada con éxito",
    fr: "La livraison a été effectuée avec succès",
    de: "Die Lieferung wurde erfolgreich durchgeführt.",
  },
  {
    id: "reso",
    it: "Reso",
    en: "Return",
    es: "Devolución",
    fr: "Retour",
    de: "Rücksendung",
  },
  {
    id: "motivo-del-reso",
    it: "Motivo del reso",
    en: "Return reason",
    es: "Motivo de la devolución",
    fr: "Raison du retour",
    de: "Grund für die Rücksendung",
  },
  {
    id: "delivery-booking",
    it: "Pianifica consegna",
    en: "Delivery booking",
    es: "Programación de su entrega",
    fr: "Réservation de la livraison",
    de: "Buchung der Lieferung",
  },
  {
    id: "richiesta-link",
    it: "Richiesta link",
    en: "Link request",
    es: "Solicitar link",
    fr: "Demande de liaison",
    de: "Antrag auf eine Verbindung",
  },
  {
    id: "daStabilire",
    it: "Da stabilire",
    en: "To be established",
    es: "Por determinar",
    fr: "A établir",
    de: "Muss noch eingerichtet werden",
  },
  {
    id: "NoSlotDisponibili",
    it: "Nessuno slot disponibile",
    en: "No slot available",
    es: "No hay ninguna franja horaria disponible",
    fr: "Pas de créneau disponible",
    de: "Es ist kein Zeitfenster verfügbar.",
  },
  {
    id: "ConsegnaNonInCarico",
    it: "Il corriere non ha ancora preso in carico la consegna",
    en: "The courier has not yet taken over the delivery",
    es: "El mensajero aún no ha realizado su entrega",
    fr: "Le coursier n'a pas encore pris en charge la livraison.",
    de: "Der Kurier hat die Lieferung noch nicht abgeholt.",
  },
  {
    id: "richiestaReso",
    it: "Richiesta reso",
    en: "Return request",
    es: "Solicitud de devolución",
    fr: "Demande de retour",
    de: "Antrag auf Rücksendung",
  },
  {
    id: "richiedi",
    it: "Richiedi",
    en: "Request",
    es: "Solicite",
    fr: "Demande",
    de: "Anfrage",
  },
  {
    id: "mailNonCorrispondente",
    it: "L'email/numero di telefono non corrisponde",
    en: "Email/phone number does not match",
    es: "El correo electrónico/número de teléfono móvil no coincide",
    fr: "L'email/le numéro de téléphone ne correspond pas",
    de: "Die E-Mail-Adresse/Telefonnummer stimmt nicht überein.",
  },
  {
    id: "mailNonCorretta",
    it: "L'email e/o la passaword non è corretta, si prega di riprovare",
    en: "Wrong Email and/or password, please retry",
    es: "El correo electrónico y/o la contraseña es incorrecto, por favor inténtelo de nuevo",
    fr: "L'adresse électronique et/ou le mot de passe sont incorrects, veuillez réessayer.",
    de: "Die E-Mail-Adresse und/oder das Passwort sind falsch, bitte versuchen Sie es erneut.",
  },
  {
    id: "linkInviato",
    it: "Il link per concludere il processo è stato inviato alla mail/numero indicato",
    en: "The link to complete the process has been sent to the email/number",
    es: "El link para finalizar el proceso ha sido enviado al correo/número indicado",
    fr: "Le lien pour compléter le processus a été envoyé à l'email/numéro.",
    de: "Der Link zum Abschließen des Vorgangs wurde an die E-Mail/die Nummer gesendet.",
  },
  {
    id: "inserisciMail",
    it: "Inserisci la tua mail o il tuo numero di telefono per ricevere il link e finalizzare la richiesta",
    en: "Enter your email or phone number to receive the link and complete the request",
    es: "Introduzca su correo electrónico o número de móvil para recibir el li",
    fr: "Saisissez votre adresse électronique ou votre numéro de téléphone pour recevoir le lien et compléter la demande.",
    de: "Geben Sie Ihre E-Mail-Adresse oder Telefonnummer ein, um den Link zu erhalten und den Antrag abzuschließen.",
  },
  {
    id: "acquistoErrore",
    it: "acquisto per errore",
    en: "purchase by mistake",
    es: "Compra por error",
    fr: "achat par erreur",
    de: "Fehler beim Kauf",
  },
  {
    id: "migliorPrezzo",
    it: "miglior prezzo disponibile",
    en: "best price available",
    es: "Mejor precio disponible",
    fr: "meilleur prix disponible",
    de: "beste verfügbare Preis",
  },
  {
    id: "qualitaNonAdeguate",
    it: "qualità non adeguate",
    en: "inadequate quality",
    es: "Calidades inadecuadas",
    fr: "qualités inadéquates",
    de: "unzureichende Qualität",
  },
  {
    id: "incomparabile",
    it: "incomparabile e inadeguato",
    en: "incomparable and inadequate",
    es: "Incomparable e inadecuado",
    fr: "incomparable et inadéquat",
    de: "unvergleichlich und unzureichend",
  },
  {
    id: "articoloDanneggiato",
    it: "articolo danneggiato, confezione esterna intatta",
    en: "damaged goods, intact package",
    es: "Artículo dañado, embalaje exterior intacto",
    fr: "article endommagé, emballage extérieur intact",
    de: "beschädigtes Gut, intakte Verpackung",
  },
  {
    id: "accessoriMancanti",
    it: "accessori mancanti",
    en: "missing parts-accessories",
    es: "Faltan accesorios",
    fr: "pièces et accessoires manquants",
    de: "fehlende Teile-Zubehör",
  },
  {
    id: "articoloConfezioneDanneggiati",
    it: "articolo e confezione esterna danneggiati",
    en: "damaged goods and package",
    es: "Artículo y embalaje exterior dañados",
    fr: "article et emballage extérieur endommagés",
    de: "beschädigtes Gut und Verpackung",
  },
  {
    id: "articoloErrato",
    it: "ricevuto articolo errato",
    en: "wrong goods",
    es: "Recibido artículo incorrecto",
    fr: "article erroné reçu",
    de: "falsches Gut erhalten",
  },
  {
    id: "return",
    it: "Pianifica reso",
    en: "Plan return",
    es: "Planifica devolucion",
    fr: "Planifier le retour",
    de: "Planen Sie die Rücksendung",
  },
  {
    id: "accedi",
    it: "Accedi",
    en: "Login",
    es: "Acceder",
    fr: "Connectez-vous à",
    de: "Login",
  },
  {
    id: "login",
    it: "Accedi",
    en: "Login",
    es: "Acceder",
    fr: "Connectez-vous à",
    de: "Login",
  },
  {
    id: "salva",
    it: "Salva",
    en: "Save",
    es: "Guardar",
    fr: "Enregistrer",
    de: "Registrieren",
  },
  {
    id: "modificaIndirizzo",
    it: "Cambia indirizzo",
    en: "Change Address",
    es: "Cambiar dirección",
    fr: "Changement d'adresse",
    de: "Adresse ändern",
  },
  {
    id: "confermaIndirizzo",
    it: "Conferma indirizzo per",
    en: "Confirm address to",
    es: "Confirmar dirección para",
    fr: "Confirmer l'adresse pour",
    de: "Bestätigen Sie die Adresse, um",
  },
  {
    id: "vedereDate",
    it: "vedere le date disponibili",
    en: "see available dates",
    es: "Ver las fechas disponibles",
    fr: "voir les dates disponibles",
    de: "die verfügbaren Termine zu sehen",
  },
  {
    id: "inserisciTracking",
    it: "Inserisci il numero di tracking per pianificare il reso",
    en: "Insert tracking number to plan the return",
    es: "Por favor, introduzca el número de seguimiento para programar su devolución",
    fr: "Veuillez saisir le numéro de suivi pour planifier votre retour",
    de: "Fügen Sie die Trackingnummer ein, um die Rücksendung zu planen.",
  },
  {
    id: "pianificaSuccesso",
    it: "Consegna pianificata con successo",
    en: "Delivery planned successfully",
    es: "Entrega programada con éxito",
    fr: "Livraison planifiée avec succès",
    de: "Erfolgreich geplante Lieferung",
  },
  {
    id: "contattaci",
    it: "CONTATTACI",
    en: "CONTACT US",
    es: "CONTÁCTENOS",
    fr: "CONTACTEZ-NOUS",
    de: "KONTAKTIEREN SIE UNS",
  },
  {
    id: "assistenzaClienti",
    it: "ASSISTENZA CLIENTI",
    en: "CUSTOMER SERVICE",
    es: "SERVICIO DE ATENCIÓN AL CLIENTE",
    fr: "SERVICE CLIENTÈLE",
    de: "KUNDENSERVICE",
  },
  {
    id: "invia",
    it: "INVIA MESSAGGIO",
    en: "SEND MESSAGE",
    es: "ENVIAR MENSAJE",
    fr: "ENVOYER UN MESSAGE",
    de: "EINE NACHRICHT SENDEN",
  },
  {
    id: "scriviRichiesta",
    it: "Invia il tuo messaggio",
    en: "Send your message",
    es: "Envíe su mensaje",
    fr: "Envoyez votre message",
    de: "Senden Sie uns Ihre Nachricht",
  },
  {
    id: "assistenza",
    it: "Contattaci per ricevere assistenza o informazione sul tuo ordine",
    en: "Contact us to receive assistance or informations about your order",
    es: "Póngase en contacto con nosotros para recibir ayuda o información sobre su pedido",
    fr: "Contactez-nous pour recevoir de l'aide ou des informations sur votre commande.",
    de: "Kontaktieren Sie uns, um Hilfe oder Informationen zu Ihrer Bestellung zu erhalten.",
  },
  {
    id: "capNonCorrisponde",
    it: "CAP e città non corrispondenti",
    en: "Mismatch postcode and city",
    es: "El código postal y la ciudad no coinciden",
    fr: "Mauvaise correspondance entre le code postal et la ville",
    de: "Falsche Übereinstimmung zwischen der Postleitzahl und der Stadt.",
  },
  {
    id: "dataConsegnaNonDisponibile",
    it: "La data di consegna non è ancora disponibile",
    en: "The delivery date is not yet available",
    es: "La fecha de entrega no está disponible todavía",
    fr: "La date de livraison n'est pas encore disponible",
    de: "Das Lieferdatum ist noch nicht verfügbar",
  },
  {
    id: "ResoSuccesso",
    it: "Reso pianificato con successo",
    en: "Return planned successfully",
    es: "Devolución programada con éxito",
    fr: "Retour planifié avec succès",
    de: "Rücksendung erfolgreich geplant",
  },
  {
    id: "PianificaConsegnaSuccesso",
    it: "Consegna pianificata con successo",
    en: "Delivery planned successfully",
    es: "Entrega programada con éxito",
    fr: "Livraison planifiée avec succès",
    de: "Erfolgreich geplante Lieferung",
  },
  {
    id: "homepage",
    it: "Torna all'homepage",
    en: "Back to homepage",
    es: "Volver a la página de inicio",
    fr: "Retour à la page d'accueil",
    de: "Zurück zur Startseite",
  },
  {
    id: "colli",
    it: "Colli",
    en: "Parcels",
    es: "Bultos",
    fr: "Colis",
    de: "Paket",
  },
  {
    id: "booking",
    it: "Booking",
    en: "Booking",
    es: "Reserva",
    fr: "Réservation",
    de: "Reservierung",
  },
  {
    id: "pod",
    it: "Pod",
    en: "Pod",
    es: "Justificante de entrega",
    fr: "Pod",
    de: "Pod",
  },
  {
    id: "reservedAcceptance",
    it: "Accettato con riserva",
    en: "Reserved acceptance",
    es: "Aceptado con reservas",
    fr: "Acceptation réservée",
    de: "Annahme gebucht",
  },
  {
    id: "reservedAcceptanceMessage",
    it: "Messaggio di accettazione",
    en: "Reserved acceptance message",
    es: "Mensaje de aceptación",
    fr: "Message d'acceptation réservée",
    de: "Nachricht zur reservierten Annahme",
  },
  {
    id: "consigneeName",
    it: "Nome destinatario",
    en: "Consignee Name",
    es: "Nombre del destinatario",
    fr: "Nom du destinataire",
    de: "Name des Empfängers",
  },
  {
    id: "signature",
    it: "Firma",
    en: "Signature",
    es: "Firma",
    fr: "Signature",
    de: "Unterschrift",
  },
  {
    id: "dateTime",
    it: "Data e ora",
    en: "Date and time",
    es: "Fecha y hora",
    fr: "Date et heure",
    de: "Datum und Uhrzeit",
  },
  {
    id: "Si",
    it: "Si",
    en: "Yes",
    es: "Si",
    fr: "Oui",
    de: "Ja",
  },
  {
    id: "posizioneNonDisponibile",
    it: "Posizione non disponibile",
    en: "Position not available",
    es: "Posición no disponible",
    fr: "Position non disponible",
    de: "Position nicht verfügbar",
  },
  {
    id: "Invia",
    it: "INVIA",
    en: "SEND",
    es: "ENVIAR",
    fr: "ENVOYER",
    de: "SENDEN",
  },
  {
    id: "whatsapp",
    it: "Whatsapp",
    en: "Whatsapp",
    es: "Whatsapp",
    fr: "Whatsapp",
    de: "Whatsapp",
  },
  {
    id: "whatsappSub",
    it: "Inserisci il numero di telefono sul quale desideri ricevere aggiornamenti sui tuoi ordini. Importante: assicurati che il numero sia lo stesso fornito in fase di creazione dell'ordine e che abbia l'applicazione WhatsApp installata.",
    en: "Enter the phone number on which you wish to receive updates on your order. Important: make sure that the number is the same as provided when creating the order and that you have the WhatsApp application installed.",
    es: "Introduzca el número de teléfono en el que desea recibir actualizaciones sobre sus pedidos. Importante: Asegúrate de que el número sea el mismo que proporcionaste cuando creaste tu pedido y que tienes instalada la aplicación Whatsapp.",
    fr: "Saisissez le numéro de téléphone sur lequel vous souhaitez recevoir les mises à jour de votre commande. Important : assurez-vous que le numéro est le même que celui fourni lors de la création de la commande et que vous avez installé l'application WhatsApp.",
    de: "Geben Sie die Telefonnummer ein, auf der Sie Updates zu Ihrer Bestellung erhalten möchten. Wichtig: Vergewissern Sie sich, dass es sich um dieselbe Nummer handelt, die Sie beim Erstellen der Bestellung angegeben haben, und dass Sie die WhatsApp-Anwendung installiert haben.",
  },
  {
    id: "cancelWhatsappSub",
    it: "Inserisci il tuo numero di telefono e ti invieremo un codice OTP temporaneo da inserire per interrompere gli aggiornamenti",
    en: "Enter your phone number and we will send you a temporary OTP code to enter to stop updates",
    es: "Introduzca su número de teléfono y le enviaremos un código  temporal para confirmar la cancelación de notificaciones.",
    fr: "Entrez votre numéro de téléphone et nous vous enverrons un code OTP temporaire à saisir pour arrêter les mises à jour.",
    de: "Geben Sie Ihre Nummer ein und wir senden Ihnen einen temporären OTP-Code, den Sie eingeben müssen, um die Aktualisierungen zu stoppen.",
  },
  {
    id: 404,
    it: "Il numero non corrisponde con quello inserito al momento dell'ordine. Riprova o contatta il servizio clienti",
    en: "The number does not match the one entered at the time of the order. Please try again or contact customer service",
    es: "El número no coincide con el número introducido en el momento del pedido. Inténtelo de nuevo o póngase en contacto con el Servicio de Atención al Cliente",
    fr: "Le numéro ne correspond pas à celui saisi lors de la commande. Veuillez réessayer ou contacter le service clientèle",
    de: "Die Nummer stimmt nicht mit der bei der Bestellung eingegebenen Nummer überein. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice",
  },
  {
    id: 401,
    it: "Non hai l'autorizzazione per eseguire questa operazione",
    en: "You do not have permission to perform this operation",
    es: "No tiene autorización para hacer esta operación",
    fr: "Vous n'avez pas l'autorisation d'effectuer cette opération",
    de: "Sie haben nicht die Erlaubnis, diesen Vorgang durchzuführen",
  },
  {
    id: 409,
    it: "Servizio già abilitato su questo numero",
    en: "Mobile number already enabled",
    es: "Servicio ya habilitado en este número",
    fr: "Numéro de mobile déjà activé",
    de: "Handynummer bereits aktiviert",
  },
  {
    id: 403,
    it: "Numero OTP sbagliato",
    en: "Wrong otp number",
    es: "Código incorrecto",
    fr: "Mauvais numéro otp",
    de: "Falsche OTP-Nummer",
  },
  {
    id: "verifica",
    it: "VERIFICA",
    en: "CHECK",
    es: "COMPRUEBE",
    fr: "VÉRIFIER",
    de: "ÜBERPRÜFEN",
  },
  {
    id: "verificaOtp",
    it: "Verifica OTP",
    en: "Check OTP",
    es: "Verifique código",
    fr: "Vérifier OTP",
    de: "Überprüfen Sie den OTP-Code",
  },
  {
    id: "verificaOtpSub",
    it: "Inserisci il codice OTP inviato al numero",
    en: "Enter the OTP code sent to the number",
    es: "Introduzca el código enviado al número",
    fr: "Entrez le code OTP envoyé au numéro",
    de: "Geben Sie den an die Nummer gesendeten OTP-Code ein",
  },
  {
    id: "numeroVerificato",
    it: "Il tuo numero è verificato",
    en: "Your number has been verified",
    es: "Su número ha sido verificado",
    fr: "Votre numéro a été vérifié",
    de: "Ihre Nummer wurde überprüft",
  },
  {
    id: "ricezioneAggiornamenti",
    it: "Riceverai aggiornamenti sullo stato del tuo ordine tramite Whatsapp",
    en: "You will receive updates on your order status via Whatsapp",
    es: "Recibirá actualizaciones sobre el estado de su pedido a través de Whatsapp",
    fr: "Vous recevrez des mises à jour sur le statut de votre commande via Whatsapp.",
    de: "Sie werden über Whatsapp Aktualisierungen zum Status Ihrer Bestellung erhalten.",
  },
  {
    id: "interrompiAggiornamento",
    it: "INTERROMPI AGGIORNAMENTI",
    en: "STOP UPDATES",
    es: "DETENER NOTIFICACIONES",
    fr: "ARRÊTER LES MISES À JOUR",
    de: "DIE AKTUALISIERUNGEN STOPPEN",
  },
  {
    id: "aggiornamentiInterrotti",
    it: "Gli aggiornamenti sullo stato del tuo ordine tramite Whatsapp sono stati interrotti",
    en: "Updates on your order status via Whatsapp have been stopped",
    es: "Las actualizaciones de su estado de pedido a través de Whatsapp han sido interrumpidas",
    fr: "Les mises à jour de l'état de votre commande via Whatsapp ont été arrêtées",
    de: "Die Aktualisierungen des Status Ihrer Bestellung über Whatsapp wurden gestoppt",
  },
  {
    id: "ritirata",
    it: "In ritiro",
    en: "Picked up",
    es: "Recogido",
    fr: "Pris en charge",
    de: "Aufgenommen",
  },
  {
    id: "registrata",
    it: "Registrata",
    en: "Registered",
    es: "Registrado",
    fr: "Enregistré",
    de: "Aufgenommen",
  },
  {
    id: "inTransito",
    it: "In viaggio",
    en: "On the way",
    es: "En camino",
    fr: "En cours d'acheminement",
    de: "Wird weitergeleitet",
  },
  {
    id: "inConsegna",
    it: "In consegna",
    en: "In delivery",
    es: "En entrega",
    fr: "En cours de livraison",
    de: "Auf dem Weg zur Auslieferung",
  },
  {
    id: "consegnata",
    it: "Consegnata",
    en: "Delivered",
    es: "Entregado",
    fr: "Livré",
    de: "Geliefert",
  },
  {
    id: "data",
    it: "Data",
    en: "Date",
    es: "Fecha",
    fr: "Date",
    de: "Datum",
  },
  {
    id: "orario",
    it: "Orario",
    en: "Time",
    es: "Horario",
    fr: "Horaire",
    de: "Uhrzeit",
  },
  {
    id: "status",
    it: "Status",
    en: "Status",
    es: "Estado",
    fr: "Statut",
    de: "Status",
  },
  {
    id: "ordineInConsegna",
    it: "Il tuo ordine è in consegna",
    en: "Your order is on delivery",
    es: "Su pedido está en entrega",
    fr: "Votre commande est en cours de livraison",
    de: "Ihre Bestellung wird geliefert",
  },
  {
    id: "ordineConsegnato",
    it: "Il tuo ordine è stato consegnato",
    en: "Your order has been delivered",
    es: "Su pedido ha sido entregado",
    fr: "Votre commande a été livrée",
    de: "Ihre Bestellung wurde ausgeliefert",
  },
  {
    id: "inPreparazione",
    it: "In preparazione",
    en: "Under preparation",
    es: "En preparación",
    fr: "En cours de préparation",
    de: "In Vorbereitung",
  },
  {
    id: "disposizioniNonRicevute",
    it: "In attesa di disposizioni dal mittente",
    en: "Provisions not yet received",
    es: "En espera de las disposiciones del remitente",
    fr: "Provisions non encore reçues",
    de: "Noch nicht erhaltene Rückstellungen",
  },
  {
    id: "disposizioniRicevute",
    it: "Disposizioni ricevute",
    en: "Provisions received",
    es: "Disposiciones recibidas",
    fr: "Dispositions reçues",
    de: "Verfügungen erhalten",
  },
  {
    id: "oggettoRichiesta",
    it: "Oggetto richiesta",
    en: "Request subject",
    es: "Objeto requerido",
    fr: "Sujet de la demande",
    de: "Thema der Anfrage",
  },
  {
    id: "testoRichiesta",
    it: "Testo richiesta",
    en: "Write here your request",
    es: "Escriba aquí su solicitud",
    fr: "Écrivez ici votre demande",
    de: "Schreiben Sie hier Ihre Anfrage",
  },
  {
    id: "inserisciTuoNumero",
    it: "Inserisci il tuo numero (con prefisso nazionale)",
    en: "Insert your phone number (with country code)",
    es: "Introduzca su número (con prefijo nacional)",
    fr: "Entrez votre numéro de téléphone (avec le code du pays)",
    de: "Fügen Sie Ihre Telefonnummer (mit Landesvorwahl) ein.",
  },
  {
    id: "selezionaSlot",
    it: "Seleziona uno slot",
    en: "Select a slot",
    es: "Seleccione una franja horaria",
    fr: "Sélectionnez un créneau",
    de: "Wählen Sie ein Zeitfenster aus",
  },
  {
    id: "companyName",
    it: "Società",
    en: "Company Name",
    es: "Nombre de empresa",
    fr: "Nom de l'entreprise",
    de: "Name des Unternehmens",
  },
  {
    id: "buildingNr",
    it: "Numero civico",
    en: "Building Number",
    es: "Número del portal",
    fr: "Numéro de bâtiment",
    de: "Nummer des Gebäudes",
  },
  {
    id: "referencePerson",
    it: "Destinatario",
    en: "Reference Name",
    es: "Destinatario",
    fr: "Nom de référence",
    de: "Name der Referenz",
  },
  {
    id: "postalCode",
    it: "CAP",
    en: "Postal code",
    es: "Código postal",
    fr: "Code postal",
    de: "Postleitzahl",
  },
  {
    id: "capNonDisponibile",
    it: "CAP non disponibile",
    en: "Postal Code non available",
    es: "Código postal no disponible",
    fr: "Code postal non disponible",
    de: "Postleitzahl nicht verfügbar",
  },
  {
    id: "city",
    it: "Città",
    en: "City",
    es: "Ciudad",
    fr: "Ville",
    de: "Stadt",
  },
  {
    id: "country",
    it: "Nazione",
    en: "Country",
    es: "País",
    fr: "Pays",
    de: "Land",
  },
  {
    id: "note",
    it: "Nota",
    en: "Note",
    es: "Nota",
    fr: "Note",
    de: "Hinweis",
  },
  {
    id: "address",
    it: "Indirizzo",
    en: "Address",
    es: "Dirección",
    fr: "Adresse",
    de: "Adresse",
  },
  {
    id: "phoneNumber",
    it: "Numero di telefono",
    en: "Phone number",
    es: "Número de teléfono",
    fr: "Numéro de téléphone",
    de: "Telefonnummer",
  },
  {
    id: "indicazioniSvincolo",
    it: "Status giacenza - istruzioni svincolo",
    en: "Storage status - Release instructions",
    es: "Guardado en depósito, a la espera de instrucciones",
    fr: "Statut de stockage - Instructions de libération",
    de: "Lagerungsstatus - Anweisungen zur Freigabe",
  },
  {
    id: "attesaIstruzioni",
    it: "In attesa di istruzioni",
    en: "Provisions not yet received",
    es: "A la espera de instrucciones",
    fr: "En attente des instructions",
    de: "Noch nicht eingegangene Verfügungen",
  },
  {
    id: "da",
    it: "da",
    en: "from",
    es: "desde",
    fr: "de",
    de: "von",
  },
  {
    id: "ordine",
    it: "Ordine",
    en: "Order",
    es: "Pedido",
    fr: "Commande",
    de: "Bestellung",
  },
  {
    id: "nazioneNonDisponibile",
    it: "Nazione non disponibile",
    en: "Country not available",
    es: "Nación no disponible",
    fr: "Pays non disponible",
    de: "Land nicht verfügbar",
  },
  {
    id: "email",
    it: "E-mail",
    en: "E-mail",
    es: "Correo electrónico",
    fr: "E-mail",
    de: "E-Mail",
  },
  {
    id: "betweenHours",
    en: "between",
    it: "tra le",
    es: "entre",
    fr: "entre",
    de: "zwischen",
  },
  {
    id: "andHours",
    en: "and",
    it: "e le",
    es: "y",
    fr: "et",
    de: "und",
  },
  {
    id: "fromHours",
    en: "from",
    it: "dalle",
    es: "desde",
    fr: "de",
    de: "von",
  },
  {
    id: "toHours",
    en: "to",
    it: "alle",
    es: "hasta",
    fr: "à",
    de: "bis",
  },
  {
    id: "trovaSpedizioni",
    it: "Trova ordini",
    en: "Find orders",
    es: "Buscar pedidos",
    fr: "Trouver des commandes",
    de: "Bestellungen finden",
  },
  {
    id: "inserisciNumeroSpedizioni",
    it: "Inserisci il numero di telefono oppure il tuo indirizzo per vedere gli ordini collegati",
    en: "Insert the phone number or your address to see the related orders",
    es: "Introduzca el número de teléfono o su dirección para ver las entregas relacionadas",
    fr: "EInsérez le numéro de téléphone ou votre adresse pour voir les commandes concernées.",
    de: "Geben Sie die Telefonnummer oder Ihre Adresse ein, um die betreffenden Bestellungen zu sehen.",
  },
  {
    id: "vediSpedizioniCollegate",
    it: "Vedi ordini collegati",
    en: "See related orders",
    es: "Ver pedidos relacionadas",
    fr: "Voir les commandes concernées",
    de: "Betroffene Bestellungen ansehen",
  },
  {
    id: "tracciaSpedizione",
    it: "Traccia il mio ordine",
    en: "Track my order",
    es: "Seguir mi pedido",
    fr: "Suivre ma commande",
    de: "Meine Bestellung verfolgen",
  },
  {
    id: "vediSingoloOrdine",
    it: "Inserisci il numero di spedizione per vedere il singolo ordine",
    en: "Insert the shipping number to see the single order",
    es: "Introduzca el número de pedido para ver el pedido individual",
    fr: "Insérez le numéro d'expédition pour voir la commande unique",
    de: "Fügen Sie die Versandnummer ein, um die einzelne Bestellung zu sehen.",
  },
  {
    id: "numeroTracking",
    it: "Numero tracking",
    en: "Tracking number",
    es: "Número de seguimiento",
    fr: "Numéro de suivi",
    de: "Verfolgungsnummer",
  },
  {
    id: "spedizioniInCorso",
    it: "Ordini in corso",
    en: "Orders in progress",
    es: "Pedidos en curso",
    fr: "Commandes en cours",
    de: "Laufende Bestellungen",
  },
  {
    id: "spedizioniConcluse",
    it: "Ordini conclusi",
    en: "Orders completed",
    es: "Pedidos concluidos",
    fr: "Commandes terminées",
    de: "Abgeschlossene Bestellungen",
  },
  {
    id: "elencoSpedizioni",
    it: "Elenco ordini",
    en: "Orders list",
    es: "Lista de pedidos",
    fr: "Liste des commandes",
    de: "Liste der Bestellungen",
  },
  {
    id: "vediSpedizione",
    it: "Vedi Ordine",
    en: "See Order",
    es: "Ver pedido",
    fr: "Voir la commande",
    de: "Bestellung ansehen",
  },
  {
    id: "tracciaOra",
    it: "Traccia ora",
    en: "Track now",
    es: "Seguir ahora",
    fr: "Suivre maintenant",
    de: "Jetzt verfolgen",
  },
  {
    id: "mailNumeroNonCorretti",
    it: "L'email e/o il numero di telefono non sono corretti, si prega di riprovare",
    en: "The email and/or phone number are not correct, please try again",
    es: "El correo electrónico y/o el número de teléfono son incorrectos, por favor, inténtelo de nuevo",
    fr: "L'adresse électronique et/ou le numéro de téléphone ne sont pas corrects, veuillez réessayer",
    de: "Die E-Mail-Adresse und/oder die Telefonnummer sind nicht korrekt, bitte versuchen Sie es erneut",
  },

  {
    id: "verificaOtpSubMail",
    it: "Inserisci il codice OTP inviato all'indirizzo email",
    en: "Enter the OTP code sent to the email address",
    es: "Introduzca el código OTP enviado a la dirección de correo electrónico",
    fr: "Entrez le code OTP envoyé à l'adresse e-mail",
    de: "Geben Sie den an die E-Mail-Adresse gesendeten OTP-Code ein",
  },
  {
    id: "reference",
    it: "Rif. Ordine",
    en: "Order Ref.",
    es: "Ref. pedido",
    fr: "Référence de la commande",
    de: "Referenz der Bestellung",
  },
  {
    id: "consignee",
    it: "Destinatario",
    en: "Consignee",
    es: "Destinatario",
    fr: "Destinataire",
    de: "Empfänger",
  },
  {
    id: "numeroOrdine",
    it: "N° Ordine",
    en: "Order Number",
    es: "Número de pedido",
    fr: "Numéro de commande",
    de: "Nummer der Bestellung",
  },
  {
    id: "lastStatusCode",
    it: "Status ordine",
    en: "Order status",
    es: "Estado del pedido",
    fr: "Date du dernier événement",
    de: "Datum des letzten Ereignisses",
  },
  {
    id: "orderDate",
    it: "Data Ordine",
    en: "Order Date",
    es: "Fecha del pedido",
    fr: "Date de commande",
    de: "Datum der Bestellung",
  },
  {
    id: "optCode not found",
    it: "Codice OTP non trovato",
    en: "OTP code not found",
    es: "Código OTP no encontrado",
    fr: "Code OTP non trouvé",
    de: "PSP-Code nicht gefunden",
  },
  {
    id: "richiediNuovoOtp",
    it: "Richiedi nuovo OTP",
    en: "Request new OTP",
    es: "Solicitar nuevo OTP",
    fr: "Demander un nouvelle OTP",
    de: "Fordern Sie ein neues PSP an",
  },
  {
    id: "autorizzazioneScaduta",
    it: "Autorizzazione scaduta. Richiedere nuovo codice di accesso",
    en: "Authorization expired. Request new access code",
    es: "Autorización caducada. Solicitar nuevo código de acceso",
    fr: "Autorisation expirée. Demander un nouveau code d'accès",
    de: "Berechtigung ist abgelaufen. Neuen Zugangscode anfordern",
  },
  {
    id: "codiceScaduto",
    it: "Codice scaduto, clicca per richiedere un nuovo codice",
    en: "Code expired, click to request a new code",
    es: "Código caducado, haga clic para solicitar un nuevo código",
    fr: "Code expiré, cliquez pour demander un nouveau code",
    de: "Code abgelaufen, klicken Sie, um einen neuen Code anzufordern",
  },
  {
    id: "nessunaSpedizione",
    it: "Nessun ordine collegato a questa utenza",
    en: "No order related to this user",
    es: "No hay pedidos relacionados con este usuario",
    fr: "Aucune commande liée à cet utilisateur",
    de: "Keine Bestellungen, die mit diesem Benutzer verknüpft sind",
  },
  {
    id: "tornaAllaRicerca",
    it: "Torna alla ricerca",
    en: "Return to search page",
    es: "Volver a la búsqueda",
    fr: "Retour à la page de recherche",
    de: "Zurück zur Suchseite gehen",
  },
  {
    id: "richiediAltroCodice",
    it: "Non hai ricevuto il codice OTP? Clicca qui per richiederne un altro",
    en: "Did not receive the OTP code? Click here to request another",
    es: "¿No ha recibido el código OTP? Haga clic aquí para solicitar otro",
    fr: "Vous n'avez pas reçu le code OTP ? Cliquez ici pour en demander un autre",
    de: "Sie haben den PSP-Code nicht erhalten? Klicken Sie hier, um einen neuen anzufordern",
  },
  {
    id: "idNonTrovato",
    it: "L'ordine non è stato trovato",
    en: "The order was not found",
    es: "El pedido no se ha encontrado",
    fr: "La commande n'a pas été trouvée",
    de: "Die Bestellung wurde nicht gefunden",
  },
  {
    id: "oppure",
    it: "oppure",
    en: "or",
    es: "o",
    fr: "ou",
    de: "oder",
  },
  {
    id: "disclaimerUtilizzoDati",
    it: "I dati inseriti saranno utilizzati esclusivamente per la verifica delle informazioni precedentemente fornite ai fini dell'erogazione del servizio",
    en: "The entered data will be used exclusively for the verification of the information previously provided for the service execution",
    es: "Los datos ingresados ​​serán utilizados exclusivamente para la verificación de la información previamente enterada con el fin de prestar el servicio.",
    fr: "Les données saisies seront utilisées exclusivement pour la vérification des informations fournies précédemment pour l'exécution du service",
    de: "Die eingegebenen Daten werden ausschließlich zur Überprüfung der zuvor gemachten Angaben für die Ausführung des Dienstes verwendet",
  },
  {
    id: "mittente",
    it: "Mittente",
    en: "Sender",
    es: "Remitente",
    fr: "Expéditeur",
    de: "Absender",
  },
  {
    id: "find-shipments",
    it: "Trova ordini",
    en: "Find orders",
    es: "Buscar pedidos",
    fr: "Trouver des commandes",
    de: "Bestellungen finden",
  },
  {
    id: "shipments-list",
    it: "Elenco ordini",
    en: "Orders list",
    es: "Lista de pedidos",
    fr: "Liste des commandes",
    de: "Bestellungen finden",
  },
  {
    id: "richiestaInviata",
    it: "La tua richiesta è stata inviata con successo.",
    en: "Your request has been sent successfully.",
    es: "Su solicitud se ha enviado con éxito.",
    fr: "Votre demande a été envoyée avec succès.",
    de: "Ihre Anfrage wurde erfolgreich abgeschickt.",
  },
  {
    id: "verificheremoRichiesta",
    it: "Verificheremo la segnalazione appena possibile.",
    en: "We will check the report as soon as possible.",
    es: "Comprobaremos el informe lo antes posible.",
    fr: "Nous la vérifierons dans les plus brefs délais.",
    de: "Wir werden sie so schnell wie möglich überprüfen.",
  },
  {
    id: "grazie",
    it: "Grazie.",
    en: "Thank you.",
    es: "Gracias.",
    fr: "Merci.",
    de: "Vielen Dank.",
  },
  {
    id: "motivo",
    it: "Motivo",
    en: "Reason",
    es: "Motivo",
    fr: "Raison",
    de: "Grund",
  },
  {
    id: "customMessage",
    it: "Messaggio personalizzato",
    en: "Custom Message",
    es: "Mensaje personalizado",
    fr: "Message personnalisé",
    de: "Persönliche Nachricht",
  },
  {
    id: "messageForYou",
    it: "Abbiamo un messaggio per te",
    en: "We've got a message for you",
    es: "Tenemos un mensaje para usted",
    fr: "Nous avons un message pour vous",
    de: "Wir haben eine Nachricht für Sie",
  },
  {
    id: "selezionaPudo",
    it: "Seleziona un punto di ritiro",
    en: "Select a pickup point",
    es: "Seleccione un punto de recogida",
    fr: "Sélectionnez un point d'enlèvement",
    de: "Wählen Sie eine Abholstelle aus.",
  },
  {
    id: "pudoText",
    it: "Ci hai ripensato e preferisci che l'ordine sia consegnato presso un punto di ritiro?",
    en: "You've changed your mind and you prefer that the order is delivered to a pickup point?",
    es: "¿Ha cambiado de opinión y prefiere que el pedido se entregue en un punto de recogida?",
    fr: "Vous avez changé d'avis et vous préférez que la commande soit livrée dans un point de retrait?",
    de: "Sie haben Ihre Meinung geändert und möchten, dass die Bestellung an eine Abholstelle geliefert wird?",
  },
  {
    id: "confermaSceltaPudo",
    it: "Confermando la scelta il pacco verrà spedito presso il punto di ritiro selezionato",
    en: "By confirming the choice the package will be sent to the selected pickup point",
    es: "Al confirmar la elección, el paquete se enviará al punto de recogida seleccionado",
    fr: "En confirmant votre choix, le colis sera envoyé au point de retrait sélectionné",
    de: "Wenn Sie Ihre Wahl bestätigen, wird das Paket an die ausgewählte Abholstelle geschickt",
  },
  {
    id: "conferma",
    it: "Conferma",
    en: "Confirm",
    es: "Confirmar",
    fr: "Confirmer",
    de: "Bestätigen",
  },
  {
    id: "select-pudo",
    it: "Seleziona punto di ritiro",
    en: "Select pickup point",
    es: "Seleccionar punto de recogida",
    fr: "Sélectionner le point d'enlèvement",
    de: "Wählen Sie die Abholstelle aus",
  },
  {
    id: "orarioApertura",
    it: "Orario di apertura",
    en: "Opening hours",
    es: "Horario de apertura",
    fr: "Heures d'ouverture",
    de: "Öffnungszeiten",
  },
  {
    id: "cambioIndirizzo",
    it: "Richiesta di cambio indirizzo in corso",
    en: "Request for address change in progress",
    es: "Solicitud de cambio de dirección en curso",
    fr: "Demande de modification d'adresse en cours",
    de: "Aktueller Antrag auf Adressänderung",
  },
  {
    id: "disponibileRitiro",
    it: "Disponibile al ritiro",
    en: "Available for pickup",
    es: "Disponible para recoger",
    fr: "Disponible pour le retrait",
    de: "Zur Abholung verfügbar",
  },
  {
    id: "ordineRitirato",
    it: "Il tuo ordine è stato ritirato",
    en: "Your order has been picked up",
    es: "Su pedido ha sido recogido",
    fr: "Votre commande a été récupérée",
    de: "Ihre Bestellung wurde abgeholt",
  },
  {
    id: "indicazioniStradali",
    it: "Indicazioni stradali",
    en: "Directions",
    es: "Indicaciones",
    fr: "Directions",
    de: "Anweisungen",
  },
  {
    id: "consegnatoPudo",
    it: "Spedizione ritirata dal PUDO",
    en: "Shipment picked up from PUDO",
    es: "Envío recogido del PUDO",
    fr: "Envoi récupéré du PUDO",
    de: "Sendung von PUDO abgeholt",
  },
  {
    id: "consegnatoPudo",
    it: "Spedizione ritirata dal PUDO",
    en: "Shipment picked up from PUDO",
    es: "Envío recogido del PUDO",
    fr: "Envoi récupéré du PUDO",
    de: "Sendung von PUDO abgeholt",
  },
  {
    id: "rateTitle",
    it: "Valuta da 1 a 10 la tua esperienza di consegna",
    en: "Please, rate your delivery experience from 1 to 10",
    es: "Evalúe del 1 al 10 su experiencia de entrega",
    fr: "Veuillez noter votre expérience de livraison de 1 à 10",
    de: "Bitte bewerten Sie Ihre Liefererfahrung von 1 bis 10",
  },
  {
    id: "rateSubTitle",
    it: "Gentile Cliente, la tua opinione è importante per noi",
    en: "Dear Customer, your feedback is valuable to us",
    es: "Apreciado cliente, tu opinión es importante para nosotros!",
    fr: "Cher client, votre opinion est importante pour nous",
    de: "Sehr geehrter Kunde, Ihre Meinung ist uns wichtig",
  },
  {
    id: "rateButton",
    it: "Invia il tuo feedback",
    en: "Confirm your rating",
    es: "Envíe su valoración",
    fr: "Envoyer votre vote",
    de: "Senden Sie Ihre Stimme",
  },
  {
    id: "rateReason",
    it: "Motivo della tua valutazione",
    en: "Rating reason",
    es: "Motivo de su evaluación",
    fr: "Raison du classement",
    de: "Bewertungsgrund",
  },
  {
    id: "rateReasonPlaceholder",
    it: "Hai qualche suggerimento o ulteriore commento?",
    en: "Do you have any suggestions or further comments?",
    es: "¿Tiene alguna sugerencia o comentario?",
    fr: "Avez-vous des suggestions ou des commentaires supplémentaires?",
    de: "Haben Sie Vorschläge oder weitere Kommentare?",
  },
  {
    id: "select",
    it: "Seleziona",
    en: "Select",
    es: "Seleccione",
    fr: "Sélectionner",
    de: "Wählen",
  },
  {
    id: "thankForRatingTitle",
    it: "Il tuo feedback è stato registrato",
    en: "Your rating has been recorded",
    es: "Su respuesta ha sido registrada",
    fr: "Votre vote a été enregistré",
    de: "Ihre Stimme wurde aufgezeichnet",
  },
  {
    id: "thankForRatingSubtitle",
    it: "Grazie del tempo che ci hai dedicato. Abbiamo ricevuto il tuo feedback e ne faremo tesoro per migliorare il nostro servizio. A presto!",
    en: "Thanks for your time. We have received your feedback and we’ll treasure it to improve our service. See you soon!",
    es: "Gracias por el tiempo que nos ha dedicado. Hemos recibido su respuesta y la tendremos en cuenta para mejorar nuestro servicio. ¡Hasta pronto!",
    fr: "Merci pour votre temps. Nous avons reçu votre avis et l'utiliserons pour améliorer notre service. A bientôt!",
    de: "Vielen Dank für Ihre Zeit. Wir haben Ihr Feedback erhalten und werden es verwenden, um unseren Service zu verbessern. Bis bald!",
  },
  {
    id: "votoEspresso",
    it: "Hai già lasciato una recensione per questo ordine. <br/> Grazie del tempo che ci hai dedicato. <br/>A presto!",
    en: "You have already left a review for this order. <br/> Thanks for your time. <br/> See you soon!",
    es: "Has dejado un comentario para este pedido. Gracias por el tiempo que nos has dedicado.  <br/> Hasta pronto!",
    fr: "Vous avez déjà laissé un avis pour cette commande. <br/> Merci pour votre temps. <br/> A bientôt!",
    de: "Sie haben bereits eine Bewertung für diese Bestellung hinterlassen. <br/> Vielen Dank für Ihre Zeit. <br/> Bis bald!",
  },
  {
    id: "consegnaPrevista",
    it: "Consegna prevista per il",
    en: "Delivery scheduled for",
    es: "Entrega prevista para el",
    fr: "Livraison prévue pour",
    de: "Lieferung geplant für",
  },
  {
    id: "resoPrevisto",
    it: "Il tuo reso verrà consegnato al mittente entro il",
    en: "Your return will be delivered to the sender by",
    es: "Tu devolución será entregada al remitente antes del",
    fr: "Votre retour sera livré à l'expéditeur avant le",
    de: "Ihre Rücksendung wird dem Absender bis zum",
  },
  {
    id: "motivoAnnullamento",
    it: "Motivo dell'annullamento",
    en: "Cancellation reason",
    es: "Motivo de la cancelación",
    fr: "Raison de l'annulation",
    de: "Grund der Stornierung",
  },
  {
    id: "rientroAlMittente",
    it: "Rientro al mittente",
    en: "Return to Sender",
    es: "Devolución al Remitente",
    fr: "Retour à l'Expéditeur",
    de: "Rücksendung an den Absender",
  },
  {
    id: "consegnatoAlMittente",
    it: "Consegnato al mittente",
    en: "Delivered to Sender",
    es: "Entregado al Remitente",
    fr: "Livré à l'Expéditeur",
    de: "An den Absender geliefert",
  },
];

const defaultLanguage = "en";

export const Text = (props) => {
  const reduxLanguage = useSelector((state) => state.language.language);
  let lan =
    localStorage.getItem("lan") ||
    reduxLanguage ||
    navigator.language.toLowerCase() ||
    defaultLanguage;

  const [language, setLanguage] = useState(lan);

  useEffect(() => {
    setLanguage(reduxLanguage);
  }, [reduxLanguage]);

  // if (!lan.includes("it") || !lan.includes("en")) {
  //   lan = "en";
  // }
  // return <div {...props}>{translate(props.label, lan) + (props.additional || '')}</div>;
  return (
    <div
      style={{ color: "var(--primary)" }}
      {...props}
      dangerouslySetInnerHTML={{
        __html: translate(props.label, language) + (props.additional || ""),
      }}
    />
  );
};
