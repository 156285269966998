import { useEffect, useState } from "react";
import "./Timer.scss";

const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const CountdownTimer = ({ targetDate, setIsTimeExpired }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice setIsTimeExpired={setIsTimeExpired} />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

const ExpiredNotice = ({ setIsTimeExpired }) => {
  useEffect(() => {
    setIsTimeExpired(true);
  }, []);

  return (
    <div className="expired-notice">
      <span>Expired!</span>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <div className="countdown-link">
        {/* <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
        <p>:</p> */}
        <DateTimeDisplay
          value={minutes}
          type={"Mins"}
          isDanger={minutes === 0 && seconds <= 30}
          isWarning={minutes === 0 && seconds > 30}
        />
        <p
          className={
            minutes === 0 && seconds < 30
              ? "countdown danger"
              : minutes === 0 && seconds > 30
              ? "countdown warning"
              : "countdown"
          }
        >
          :
        </p>
        <DateTimeDisplay
          value={seconds}
          type={"Seconds"}
          isDanger={minutes === 0 && seconds <= 30}
          isWarning={minutes === 0 && seconds > 30}
        />
      </div>
    </div>
  );
};

const DateTimeDisplay = ({ value, type, isDanger, isWarning }) => {
  return (
    <div
      className={
        isDanger
          ? "countdown danger"
          : isWarning
          ? "countdown warning"
          : "countdown"
      }
    >
      <p>{value.toString().length < 2 ? "0" + value : 0 + value}</p>
      {/* <span>{type}</span> */}
    </div>
  );
};

export default CountdownTimer;
