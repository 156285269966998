import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Text } from "../../../assets/lemmata";
import { Card } from "../../../components/Cards/Cards";
import { joinArray } from "../../../reducers/pudos";
import { Button, ButtonLink } from "../../../shared/Buttons";
import { Spinner } from "../../../shared/Spinner";
import { cleanUppercase } from "../../../utils/capitalize";
import { PudoSlotsPopup } from "../../PudoPage/PudoPage";

const PudoCard = ({
  shipping,
  pudos,
  loading,
  isSchedulingAvailable,
  isShippingDelivering,
}) => {
  const [isPudoSlotPopupOpen, setIsPudoSlotPopupOpen] = useState(false);

  const slotsArray = shipping.addressChangeRequestPending?.pudo?.slots?.map(
    (weekDay) => {
      /*      console.log("weekDay", weekDay); */
      return weekDay?.weekDays?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr]:
            moment().set("H", weekDay.fromTime).format("HH") +
            ":" +
            moment().set("m", weekDay.fromTimeMinutes).format("mm") +
            " - " +
            moment().set("H", weekDay.toTime).format("HH") +
            ":" +
            moment().set("m", weekDay.toTimeMinutes).format("mm"),
        };
      }, {});
    }
  );

  const deliveredSlotsArray = shipping?.pudo?.slots?.map((weekDay) => {
    /*      console.log("weekDay", weekDay); */
    return weekDay?.weekDays?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]:
          moment().set("H", weekDay.fromTime).format("HH") +
          ":" +
          moment().set("m", weekDay.fromTimeMinutes).format("mm") +
          " - " +
          moment().set("H", weekDay.toTime).format("HH") +
          ":" +
          moment().set("m", weekDay.toTimeMinutes).format("mm"),
      };
    }, {});
  });

  const handleNav = (lat, lon) => {
    const platform = navigator?.userAgentData?.platform || navigator?.platform;
    if (
      platform.indexOf("iPhone") !== -1 ||
      platform.indexOf("iPod") !== -1 ||
      platform.indexOf("iPad") !== -1
    ) {
      window.open(
        `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lon}`
      );
    } else {
      window.open(
        `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lon}`
      );
    }
  };

  let slots = "";
  if (slotsArray?.length > 0) {
    slots = joinArray(slotsArray);
  }
  let deliveredSlots = "";
  if (deliveredSlotsArray?.length > 0) {
    deliveredSlots = joinArray(deliveredSlotsArray);
  }

  const formattedPudo = {
    ...shipping.addressChangeRequestPending?.pudo,
    slots,
  };

  const formattedDeliveredPudo = {
    ...shipping.pudo,
    slots: deliveredSlots,
  };

  return (
    <>
      {isPudoSlotPopupOpen && (
        <PudoSlotsPopup
          address={
            shipping.addressChangeRequestPending?.pudo?.address ||
            shipping.pudo?.address
          }
          pudo={!!formattedPudo.slots ? formattedPudo : formattedDeliveredPudo}
          isOpen={isPudoSlotPopupOpen}
          setIsOpen={setIsPudoSlotPopupOpen}
        />
      )}
      {!!shipping.addressChangeRequestPending?.pudo ? (
        <Card
          minHeight={isSchedulingAvailable && !isShippingDelivering && "240px"}
          paddingCard="16px 24px 24px 24px"
          paddingBottomContainer="0"
          /*          cardJustifyContent="center" */

          /*  width={shipping?.pod && "32%"} */
        >
          <h2 className="mb-05">
            <Text
              style={{
                fontWeight: 500,
                fontSize: "0.9em",
                color: "var(--primary)",
              }}
              label="indirizzo"
            />
          </h2>
          <div className="address-lines mb-1">
            <div
              style={{
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              {" "}
              {cleanUppercase(
                shipping.addressChangeRequestPending.pudo?.address?.companyName
              )}
            </div>
            <div>
              {cleanUppercase(
                shipping.addressChangeRequestPending.pudo?.address?.street
              )}{" "}
              {cleanUppercase(
                shipping.addressChangeRequestPending.pudo?.address?.buildingNr
              )}
            </div>
            <div>
              {shipping.addressChangeRequestPending.pudo?.address?.postalCode}
              {", "}
              {cleanUppercase(
                shipping.addressChangeRequestPending.pudo?.address?.city
              )}
              {", "}
              {cleanUppercase(
                shipping.addressChangeRequestPending.pudo?.address?.country
              )}
            </div>
          </div>
          <Button
            handleclick={() => setIsPudoSlotPopupOpen(true)}
            marginTop={"0px"}
          >
            <Text
              style={{
                fontWeight: 500,
                textAlign: "center",
              }}
              label="orarioApertura"
            />
          </Button>
        </Card>
      ) : (
        <Card
          paddingCard="16px 24px 24px 24px"
          width={shipping?.pod && "100%"}
          paddingBottomContainer="0"
        >
          <h2 className="mb-05">
            <Text
              style={{
                fontWeight: 500,
                fontSize: "0.9em",
                color: "var(--primary)",
              }}
              label="indirizzo"
            />
          </h2>
          {loading ? (
            <Spinner />
          ) : (
            shipping?.deliveryAddress && (
              <div className="address-lines mb-1" style={{ fontSize: 14 }}>
                {!!shipping.deliveryAddress.referencePerson && (
                  <div style={{ lineHeight: "20px" }}>
                    {cleanUppercase(shipping.deliveryAddress.referencePerson)}
                  </div>
                )}
                {!!shipping.deliveryAddress.companyName && (
                  <div style={{ lineHeight: "20px" }}>
                    {cleanUppercase(shipping.deliveryAddress.companyName)}
                  </div>
                )}
                <div style={{ lineHeight: "20px" }}>
                  {cleanUppercase(shipping.deliveryAddress.street)}{" "}
                  {cleanUppercase(shipping.deliveryAddress.buildingNr)}
                </div>
                <div>
                  {shipping.deliveryAddress.postalCode}
                  {", "}
                  {cleanUppercase(shipping.deliveryAddress.city)}
                  {", "}
                  {cleanUppercase(shipping.deliveryAddress.country)}
                </div>
              </div>
            )
          )}
          {pudos?.length > 0 &&
            !shipping.addressChangeRequestPending &&
            !shipping?.provisionModeId && (
              <>
                <Text
                  label="pudoText"
                  style={{
                    fontSize: "0.9rem",
                    color: "var(--primary)",
                    fontWeight: 300,
                  }}
                />
                <div className="flex-row-center">
                  <Link className="link-style" to="/select-pudo">
                    <ButtonLink
                      color="#fff"
                      marginTop="12px"
                      backgroundColor="var(--primary)"
                    >
                      <Text style={{ color: "#fff" }} label="selezionaPudo" />
                    </ButtonLink>
                  </Link>
                </div>
              </>
            )}
          {!!shipping.addressChangeRequestPending && (
            <Text
              className="mt-1"
              style={{
                fontWeight: 500,
                fontSize: "0.8rem",
                color: "var(--primary)",
                textAlign: "center",
              }}
              label="cambioIndirizzo"
            />
          )}
          {(!!shipping.addressChangeRequestPending || !!shipping.pudo) && (
            <>
              <Button
                handleclick={() => setIsPudoSlotPopupOpen(true)}
                marginTop={"0px"}
              >
                <Text
                  style={{
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                  label="orarioApertura"
                />
              </Button>
              <Button
                handleclick={() =>
                  handleNav(
                    shipping.deliveryAddress.latitude,
                    shipping.deliveryAddress.longitude
                  )
                }
                marginTop={"8px"}
              >
                <Text
                  style={{
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                  label="indicazioniStradali"
                />
              </Button>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default PudoCard;
