import React from "react";
import styled from "styled-components";
import "./Cards.scss";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.paddingTopContainer || "16px"};
  padding-bottom: ${(props) => props.paddingBottomContainer || "16px"};
  min-height: ${(props) => props.minHeight};
  width: ${(props) => props.width};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.fontSize || "1.5rem"};
  font-weight: 600;
  padding-bottom: 16px;
  text-align: ${(props) => props.titleAlign || "left"};
  color: var(--primary);
`;
const StyledCard = styled.div`
  display: flex;
  flex-direction: ${(props) => props.cardDirection || "column"};
  flex-grow: 1;
  align-items: ${(props) => props.cardAlignItems};
  justify-content: ${(props) => props.cardJustifyContent};
  background-color: ${(props) => props.backgroundColor || "#fff"};
  border-radius: 16px;
  padding: ${(props) => props.paddingCard || "24px"};
  min-height: ${(props) => props.minHeight || "0px"};
  @media (max-width: 768px) {
    padding: ${(props) => props.paddingCardMobile || "24px"};
    width: 100%;
  }
`;
export const Card = (props) => {
  return (
    <Container
      {...props}
      className={props.half ? "card-half-size" : "card-full-size"}
    >
      {!!props.cardTitle && <Title {...props}>{props.cardTitle}</Title>}
      <StyledCard {...props}>{props.children}</StyledCard>
    </Container>
  );
};
