import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { BackBtn, Button } from "../../shared/Buttons";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import SideSection from "../../components/SideSection/SideSection";
import { Link } from "react-router-dom";
import { Text } from "../../assets/lemmata";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { Popup } from "../../components/Popups/Popup";
import { getAllPudos } from "../../reducers/pudos";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
// import { ReactComponent as MagnifyingGlass } from "../../assets/icons/magnifying-glass.svg";
import PudosMap from "./PudosMap";
import { Input } from "../../shared/Inputs";
import styled from "styled-components";
import "./PudoPage.scss";
import Logo1AEdicola from "../../assets/img/Logo1AEdicola.png";
import { sendShippingToPudo } from "../../reducers/shippings";
import { cleanUppercase } from "../../utils/capitalize";
import moment from "moment";

const PudoPage = (props) => {
  const dispatch = useDispatch();
  const id = localStorage.getItem("id");
  const key = localStorage.getItem("key");
  // const hasBackPage = props.history?.action === "PUSH";
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedPudo, setSelectedPudo] = useState(null);
  // const [search, setSearch] = useState("");
  const getScreenWidth = () => {
    return window.innerWidth;
  };
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const pudos = useSelector((state) => state.pudos.data?.pudos);
  const loading = useSelector((state) => state.pudos.loading);
  // const [language, setLanguage] = useState(
  //   localStorage.getItem("lan") || navigator.language.toLowerCase()
  // );
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.shipping && key && !pudos) {
      // const address = props.shipping.deliveryAddress;
      props.getAllPudos(id, key);
      // setSearch(
      //   `${address.street}, ${address.city}, ${address.postalCode}, ${address.country}`
      // );
    }
  }, [props.shipping, key]);

  useEffect(() => {
    if (!!selectedPudo) {
      // scroll to selected pudo
      const pudoRow = document.getElementById(selectedPudo.id);
      pudoRow.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selectedPudo]);

  useEffect(() => {
    if (!!props.pudoSetted) {
      /* console.log("PUDO SETTED", props.pudoSetted); */
      props.history.push("/");
    }
  }, [props.pudoSetted]);

  const handleConfirmPudo = () => {
    dispatch(sendShippingToPudo(id, selectedPudo, key));
  };

  return (
    <div className="main-container">
      {isPopupOpen && (
        <Popup cardAlignItems="center" cardWidth="35%">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
              position: "absolute",
              top: "16px",
              right: "16px",
              overflow: "auto",
            }}
          >
            <Close
              onClick={() => setPopupOpen(!isPopupOpen)}
              width={24}
              height={24}
            />
          </div>
          <Text
            style={{
              textAlign: "center",
              color: "var(--primary)",
              fontWeight: 500,
              fontSize: "1.5rem",
            }}
            label="confermaSceltaPudo"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <h4
              style={{
                fontWeight: "600",
                fontSize: "1.1rem",
                color: "var(--primary)",
              }}
            >
              {`TYP Stop ${selectedPudo.id}`}
            </h4>

            <div>
              <p
                style={{
                  fontSize: "0.9rem",
                  textAlign: "center",
                  color: "var(--primary)",
                  fontWeight: 500,
                }}
              >
                {cleanUppercase(selectedPudo.address?.companyName)}
              </p>
              <p style={{ fontSize: "0.9rem", textAlign: "center" }}>
                {cleanUppercase(
                  `${selectedPudo.address?.street}, ${selectedPudo.address?.city}, ${selectedPudo.address.postalCode}`
                )}
              </p>
            </div>
          </div>
          <Button marginTop={"10px"} handleclick={() => handleConfirmPudo()}>
            <Text style={{ color: "#fff" }} label="conferma" />
          </Button>
        </Popup>
      )}
      <SideSection />
      <div className="right-container">
        <MobileHeader backBtn />
        <div className="flex-row title-container">
          <div className="desktop-btn">
            <BackBtn>
              <Link to={`/`} className="flex-row-center">
                <ArrowLeft width={24} height={32} />
              </Link>
            </BackBtn>
          </div>
          <div className="title-style padding-desktop">
            <Text label="selezionaPudo" />
          </div>
        </div>
        {!loading && pudos?.length > 0 && (
          <>
            <div
              className="mt-2"
              style={{
                display: "flex",
                justifyContent: "center ",
                padding: "0 10%",
                flexWrap: "wrap",
              }}
            >
              <div
                className="half-width"
                style={{
                  backgroundColor: "white",
                  marginBottom: screenWidth < 768 && "2rem",
                  // borderTopLeftRadius: "16px",
                  // borderBottomLeftRadius: "16px",
                }}
              >
                {/* <div
                  style={{
                    position: "relative",
                    padding: "0px 10px",
                    height: 60,
                  }}
                >
                  <Input value={search} />
                  <div
                    style={{
                      position: "absolute",
                      top: "32px",
                      right: "5%",
                      transform: "translate(0%, -50%)",
                      zIndex: 200,
                    }}
                  >
                    <MagnifyingGlass fill="var(--primary)" width={24} />
                  </div>
                </div> */}
                <div
                  className="sidebar-nav"
                  style={{ overflowY: "auto", height: "70vh" }}
                >
                  {pudos?.map((pudo) => (
                    <PudoRow
                      key={pudo.id}
                      handleSelect={() => setSelectedPudo(pudo)}
                      pudo={pudo}
                      selectedPudo={selectedPudo}
                      language={props.language}
                    />
                  ))}
                </div>
              </div>
              <PudosMap
                width={screenWidth > 768 ? "50%" : "100%"}
                pudos={pudos}
                selectedPudo={selectedPudo}
                setSelectedPudo={setSelectedPudo}
                shipping={props.shipping}
              />
            </div>
            <div
              className="mt-2 mb-2"
              style={{
                display: "flex",
                justifyContent: "center ",
                padding: "0 16px",
              }}
            >
              <Button handleclick={() => setPopupOpen(!isPopupOpen)}>
                <Text
                  style={{ color: "#fff", fontWeight: 500 }}
                  label="conferma"
                />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  language: state.language.language,
  shipping: state.shippings.data,
  pudos: state.pudos.data,
  pudoSetted: state.shippings.pudoSetted,
  loading: state.pudos.loading,
  error: state.pudos.error,
});

export default connect(mapStateToProps, { getAllPudos })(PudoPage);

const StyledPudoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px 12px 16px 12px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &.selected {
    background-color: #f5f5f5;
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const PudoRow = ({ pudo, handleSelect, selectedPudo, language }) => {
  const [isOpen, setIsOpen] = useState(false);
  const address = pudo?.address;

  return (
    <StyledPudoRow
      onClick={() => handleSelect()}
      className={pudo === selectedPudo && "selected"}
      id={pudo.id}
    >
      {isOpen && (
        <PudoSlotsPopup
          address={address}
          pudo={pudo}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <img
            src={Logo1AEdicola}
            alt=""
            style={{
              width: 40,
              height: 40,
              marginRight: 12,
              alignSelf: "center",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",

              flex: 1,
            }}
          >
            <h4
              style={{
                fontWeight: "600",
                fontSize: "1.1rem",
                color: "var(--primary)",
              }}
            >
              {/* {address?.companyName} */}
              {`TYP Stop ${pudo.id}`}
            </h4>

            <div>
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "var(--primary)",
                  fontWeight: 500,
                }}
              >
                {cleanUppercase(address?.companyName)}
              </p>
            </div>
          </div>
        </div>
        <div>
          <p style={{ fontSize: "0.9rem" }}>
            {cleanUppercase(
              `${address?.street}, ${address?.city}, ${address.postalCode}`
            )}
          </p>
        </div>
      </div>
      <div className="distance-hours">
        <p
          style={{
            textTransform: "uppercase",
            fontWeight: "600",
            fontSize: "0.8rem",
            color: "var(--primary)",
            textAlign: "right",
          }}
        >
          {language.includes("it")
            ? `Distante ${Math.round(pudo?.distance * 10) / 10} km`
            : `Distance ${Math.round(pudo?.distance * 10) / 10} km`}
        </p>
        <h5
          style={{
            textAlign: "right",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Text
            style={{ textDecoration: "underline" }}
            label="orarioApertura"
          />
        </h5>
      </div>
    </StyledPudoRow>
  );
};

const days = [
  { key: "MONDAY", day: 1 },
  { key: "TUESDAY", day: 2 },
  { key: "WEDNESDAY", day: 3 },
  { key: "THURSDAY", day: 4 },
  { key: "FRIDAY", day: 5 },
  { key: "SATURDAY", day: 6 },
  { key: "SUNDAY", day: 0 },
];

export const PudoSlotsPopup = (props) => {
  // get current day of the week
  const currentDay = new Date().getDay();
  return (
    <Popup cardAlignItems="center" cardWidth="35%">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          position: "absolute",
          top: "16px",
          right: "16px",
          overflow: "auto",
        }}
      >
        <Close
          onClick={() => props.setIsOpen(!props.isOpen)}
          width={24}
          height={24}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <h4
          style={{
            fontWeight: "600",
            fontSize: "1.1rem",
            color: "var(--primary)",
          }}
        >
          {`TYP Stop ${props.pudo.id}`}
        </h4>

        <div>
          <p
            style={{
              fontSize: "0.9rem",
              color: "var(--primary)",
              fontWeight: 500,
            }}
          >
            {cleanUppercase(props.address?.companyName)}
          </p>
          <p style={{ fontSize: "0.9rem", color: "var(--primary)" }}>
            {cleanUppercase(
              `${props.address?.street}, ${props.address?.city}, ${props.address.postalCode}`
            )}
          </p>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {days.map((day) => {
          const isToday = day.day === currentDay;
          return (
            <div
              key={day.day}
              style={{
                display: "flex",
                color: isToday ? "var(--secondary)" : "var(--primary)",
                fontWeight: isToday ? "500" : "400",
              }}
            >
              <div style={{ flex: 2 }}>
                {cleanUppercase(
                  moment()
                    .set("day", day.day)
                    .locale(localStorage.getItem("lan"))
                    .format("dddd")
                )}
              </div>
              {props.pudo?.slots[day.key]?.map((slot) => {
                return <div style={{ flex: 2 }}>{slot}</div>;
              })}
            </div>
          );
        })}
      </div>
    </Popup>
  );
};
