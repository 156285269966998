import { ReactComponent as ExclamationPoint } from "../../assets/icons/exclamation-point.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";

import "./TrackingSection.scss";
import {
  getReachedStep,
  isLastEventError,
  isSectionError,
  isSectionWarning,
} from "./FormatEventsFunc";

const TrackingLine = (props) => {
  const reachedStep = getReachedStep(props.trackingEvents);
  const isLastEventOK =
    props.trackingEvents[props.trackingEvents.length - 1].status === "ok";
  const isReturnToSender = props.provisionId === 1;
  // const isError = isLastEventError(props.trackingEvents);
  return (
    <div className="tracking-line">
      {props.trackingSections.map((section, i) => {
        const isChecked = section.id <= reachedStep;
        const sectionEvents = props.trackingEvents.filter(
          (event) => event.stepCode === section.id
        );
        const isError = isSectionError(props.trackingEvents, section.id);
        const isWarning = isSectionWarning(props.trackingEvents, section.id);
        return (
          <div key={section.id} className="tracking-steps">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="tracking-dot"
                style={{
                  // backgroundColor: isChecked
                  //   ? "var(--secondary)"
                  //   : sectionEvents.length > 0
                  //   ? "#111"
                  //   : "#ddd",
                  backgroundColor:
                    (isLastEventOK && isChecked) ||
                    (isChecked && !isLastEventOK && isReturnToSender)
                      ? "var(--secondary)"
                      : isWarning
                      ? "var(--primary)"
                      : isError
                      ? "var(--primary)"
                      : isChecked
                      ? "var(--secondary)"
                      : "#ddd",
                }}
              >
                {(isLastEventOK && isChecked) ||
                (isChecked && !isWarning && !isError) ||
                (isChecked && !isLastEventOK && isReturnToSender) ? (
                  <>
                    <div className="tracking-line-icon">{section.icon}</div>
                    <div
                      className="apex-dot"
                      style={{
                        backgroundColor: "var(--bgSecondaryBtnColor)",
                        // border: "1px solid var(--iconColor)",
                      }}
                    >
                      <Check
                        width={14}
                        height={14}
                        style={{
                          stroke: "var(--checkColor)",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {section.iconWhite}
                    {sectionEvents.length > 0 && (
                      <div
                        className="apex-dot"
                        style={{
                          backgroundColor: isError
                            ? "var(--danger)"
                            : "var(--warning)",
                        }}
                      >
                        <ExclamationPoint width={24} height={24} />
                      </div>
                    )}
                  </>
                )}
              </div>
              {sectionEvents?.find(
                (event) => event.eventCode === 82 || event.eventCode === 83
              ) ? (
                <div className="tracking-title">
                  {section.titlePudo || section.title}
                </div>
              ) : (
                <div className="tracking-title">{section.title}</div>
              )}
            </div>
            {i < props.trackingSections.length - 1 && (
              <div
                className="line"
                style={{
                  // backgroundColor: isChecked
                  //   ? "var(--secondary)"
                  //   : sectionEvents.length > 0
                  //   ? "#111"
                  //   : "#ddd",
                  backgroundColor:
                    (isLastEventOK && isChecked) ||
                    (isChecked && !isLastEventOK && isReturnToSender)
                      ? "var(--secondary)"
                      : isWarning
                      ? "var(--primary)"
                      : isError
                      ? "var(--primary)"
                      : isChecked
                      ? "var(--secondary)"
                      : "#ddd",
                }}
              ></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TrackingLine;
