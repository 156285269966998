import React from "react";
import styled from "styled-components";
import { Text } from "../assets/lemmata";
import { ButtonLink } from "./Buttons";

const prefixesList = [
  { value: "+39", label: "+39" },
  { value: "+33", label: "+33" },
  ,
  { value: "+49", label: "+49" },
  ,
  { value: "+31", label: "+31" },
  { value: "+34", label: "+34" },
  { value: "+351", label: "+351" },
];
const StyledSelect = styled.select`
  padding: ${(props) => props.padding || "16px 0"};
  font-size: 16px;
  width: ${(props) => props.width || "100%"};
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) => props.border || "none"};
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 6L0 0h10L5 6z' fill='var(--primary)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 24px) center;
  background-size: 10px 6px;
`;

export const Select = (props) => {
  return (
    <StyledSelect {...props} id="mySelect">
      {props.children}
    </StyledSelect>
  );
};

const StyledInput = styled.input`
  padding: ${(props) =>
    props.prefixSelect
      ? "12px 16px 12px 100px"
      : props.icon
      ? "12px 16px 12px 56px"
      : "12px 24px"};
  font-size: 16px;
  width: ${(props) => props.width || "100%"};
  margin: 8px 0;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) =>
    props.border || props.error ? "2px red solid" : "2px #f5f5f3 solid"};
  border-radius: 50px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Poppins", sans-serif;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  @media screen and (max-width: 768px) {
    max-width: 350px;
  }
`;

const StyledTextarea = styled.textarea`
  padding: ${(props) => (props.icon ? "16px 16px 16px 56px" : "16px 24px")};
  min-height: 25vh;
  flex-grow: 1;
  resize: none;
  font-size: 16px;
  width: ${(props) => props.width || "100%"};
  margin: 12px 0;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) => props.border || "none"};
  border-radius: 24px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Poppins", sans-serif;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  height: 24px;
`;

export const Input = (props) => {
  if (props.textarea) {
    return (
      <div style={{ position: "relative" }}>
        <StyledTextarea
          disabled={props.disabled}
          onChange={(e) => props.handleChange(props.target, e.target.value)}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          type={props.type}
          {...props}
        />
      </div>
    );
  } else {
    return (
      <>
        <div style={{ position: "relative" }}>
          <StyledInput
            disabled={props.disabled}
            maxLength={props.maxLength}
            onChange={(e) => props.handleChange(props.target, e.target.value)}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            type={props.type}
            value={props.value}
            autoComplete={props.autocomplete}
            {...props}
          />
          {props.handleIconClick ? (
            <IconContainer
              style={{ cursor: "pointer" }}
              onClick={() => props.handleIconClick()}
            >
              {props.icon}
            </IconContainer>
          ) : (
            <IconContainer>{props.icon}</IconContainer>
          )}
          {props.prefixSelect && (
            <StyledSelect
              width="40px"
              padding="0px 0px"
              style={{
                position: "absolute",
                top: props.prefixSelectMarginTop || 2,
                left: 56,
                fontFamily: "Poppins",
                textAlign: "center",
                borderRadius: 0,
                boxShadow: "0 0 3px #aaa",
                height: 50,
              }}
              onChange={(e) => props.prefixSelectHandleChange(e.target.value)}
            >
              {" "}
              {prefixesList.map((prefix, i) => (
                <option
                  style={{ fontFamily: "Poppins" }}
                  key={`prefix${i}_${prefix.value}`}
                  value={prefix.value}
                >
                  {prefix.label}
                </option>
              ))}
            </StyledSelect>
          )}
        </div>
        {props.error && (
          <Text
            style={{
              fontSize: "0.8rem",
              fontWeight: "500",
              color: "red",
              // paddingLeft: "56px",
            }}
            label={props.errorLabel}
          />
        )}
      </>
    );
  }
};

const StyledInputWithButton = styled.input`
  padding: 24px 5% 24px 15%;
  width: 200%;
  height: 48px;
  border-radius: 50px;
  background-color: #f5f5f3;
  border: ${(props) =>
    props.border || props.error ? "2px red solid" : "2px #f5f5f3 solid"};
  color: #000;
  outline: none;
  font-family: "Poppins", sans-serif;
  &::placeholder {
    font-size: 16px;
  }
`;

export const InputWithButton = (props) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        minWidth: "350px",
      }}
    >
      <StyledInputWithButton
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        onChange={(e) => props.handleChange(e.target.value)}
        // onKeyDown={(e) => props.handleKeyDown(e)}
        {...props}
      />
      {props.icon}
      <ButtonLink
        // disabled={props.btnDisabled}
        onClick={() => props.handleclick()}
        marginTop={"0px"}
        paddingVertical={0}
        // color="#fff"
        style={{
          marginLeft: -2,
          height: 44,
          width: 44,
          position: "absolute",
          top: 4,
          right: 4,
          borderBottomRightRadius: 50,
          borderTopRightRadius: 50,
          borderBottomLeftRadius: 50,
          borderTopLeftRadius: 50,
          borderColor: "var(--toggleSectionIconColor)",
          backgroundColor: "var(--toggleSectionIconColor)",
        }}
      >
        {props.label}
      </ButtonLink>
    </div>
  );
};

const StyledSingleDigitInput = styled.input`
  padding: ${(props) => props.padding || "12px 24px"};
  font-size: 16px;
  width: 80px;
  margin: 12px 0;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: center;
  color: ${(props) => props.color || "#222"};
  background-color: ${(props) => props.backgroundColor || "#f5f5f3"};
  border: ${(props) =>
    props.border || props.error ? "2px red solid" : "2px #f5f5f3 solid"};
  border-radius: 50px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Poppins", sans-serif;
  &:disabled {
    display: none;
  }
  @media (max-width: 564px) {
    width: 48px;
    height: 48px;
    padding: 0;
  }
  text-align: center;
`;

export const SingleDigitInput = (props) => {
  return (
    <>
      <StyledSingleDigitInput
        maxLength={props.maxLength}
        onChange={(e) => {
          props.handleFocus(e);
          props.handleChange(props.target, e.target.value, e);
        }}
        defaultValue={props.defaultValue}
        type={"number"}
        value={props.value}
        onKeyDown={props.onKeyPress}
        disabled={props.disabled}
        autoComplete="off"
      />
    </>
  );
};
