import { Popup } from "../../../components/Popups/Popup";
import { ReactComponent as Close } from "../../../assets/icons/close.svg";
const ConfirmedReturnPopup = (props) => {
  return (
    <Popup style={{ minHeight: 240 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          position: "absolute",
          top: "16px",
          right: "16px",
        }}
      >
        <Close onClick={() => props.close()} width={24} height={24} />
      </div>

      <div
        className="return-complete-popup"
        dangerouslySetInnerHTML={{ __html: props.message }}
      ></div>
    </Popup>
  );
};

export default ConfirmedReturnPopup;
