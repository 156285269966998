import axios from "axios";
import api from "../server/api";

const GET_RATING_OPTIONS_REQUEST = "TYP/GET_RATING_OPTIONS_REQUEST";
const GET_RATING_OPTIONS_SUCCESS = "TYP/GET_RATING_OPTIONS_SUCCESS";
const GET_RATING_OPTIONS_FAILURE = "TYP/GET_RATING_OPTIONS_FAILURE";

const CREATE_NEW_RATE_REQUEST = "TYP/CREATE_NEW_RATE_REQUEST";
const CREATE_NEW_RATE_SUCCESS = "TYP/CREATE_NEW_RATE_SUCCESS";
const CREATE_NEW_RATE_FAILURE = "TYP/CREATE_NEW_RATE_FAILURE";

const initialState = {
  loading: false,
  error: false,
  ratingOptions: [],
  rateSent: false,
};

export default function reducerSlots(state = initialState, action) {
  switch (action.type) {
    case GET_RATING_OPTIONS_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_RATING_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        ratingOptions: action.payload,
      };
    case GET_RATING_OPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        ratingOptions: [],
        error: action.payload,
      };
    case CREATE_NEW_RATE_REQUEST:
      return { ...state, loading: true, error: false };
    case CREATE_NEW_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        rateSent: true,
      };
    case CREATE_NEW_RATE_FAILURE:
      return {
        ...state,
        rateSent: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

export const getRatingOptions = (shipmentId, guestKey, language) => {
  return async (dispatch) => {
    dispatch({
      type: GET_RATING_OPTIONS_REQUEST,
    });
    try {
      const { url, method, headers } = api.getRatingOptions(
        shipmentId,
        guestKey,
        language
      );

      const options = await axios({
        url,
        method,
        headers,
      });
      dispatch({
        type: GET_RATING_OPTIONS_SUCCESS,
        payload: options.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
      dispatch({
        type: GET_RATING_OPTIONS_FAILURE,
        payload: error,
      });
    }
  };
};

export const createNewRate = (shipmentId, guestKey, rateData) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_NEW_RATE_REQUEST,
    });
    try {
      const { url, method, headers, params, data } = api.createNewRate(
        shipmentId,
        guestKey,
        rateData
      );
      const rate = await axios({
        url,
        method,
        headers,
        data,
        params,
      });

      console.log("create rate", rate.data);

      dispatch({
        type: CREATE_NEW_RATE_SUCCESS,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error ", error.response);
      dispatch({
        type: CREATE_NEW_RATE_FAILURE,
        payload: error.response,
      });
    }
  };
};
