import { API_ROOT_URL } from "../config/server";

const getHeaders = (language) => {
  const lan = language || localStorage.getItem("lan") || navigator.language;
  const headers = {
    "Content-Type": "application/json",
    "Accept-Language": lan,
  };
  return headers;
};

// const getAuthHeaders = () => {
//   const account = localStorage.getItem("apiKey");
//   let key = "";

//   if (account) {
//     const apiKey = JSON.parse(account);
//     key = apiKey.apiKey;
//   }

//   return {
//     ...getHeaders(),
//     Authorization: `Bearer ${key}`,
//   };
// };

const requests = {
  elevatePrivileges: (credentials) => {
    return {
      url: `${API_ROOT_URL}/elevation`,
      method: "POST",
      headers: getHeaders(),
      data: credentials,
    };
  },
  getTrackingData: (id, key, language) => {
    return {
      url: `${API_ROOT_URL}/tracking/${id}`,
      method: "GET",
      headers: getHeaders(language),
      params: key ? { key } : null,
    };
  },
  getTrackingDataWithCode: (reference, contractCode, language) => {
    return {
      url: `${API_ROOT_URL}/tracking`,
      method: "GET",
      headers: getHeaders(language),
      params: {
        contractCode,
        reference,
      },
    };
  },
  getTrackingDataWithCustomerCode: (
    customerReference,
    customerCode,
    language
  ) => {
    return {
      url: `${API_ROOT_URL}/tracking`,
      method: "GET",
      headers: getHeaders(language),
      params: {
        customerCode,
        customerReference,
      },
    };
  },

  getShippingById: (id, key) => {
    return {
      url: `${API_ROOT_URL}/shipments/${id}`,
      method: "GET",
      headers: getHeaders(),
      params: { key },
    };
  },

  getShippingLogo: (contractId) => {
    return {
      url: `${API_ROOT_URL}/contracts/${contractId}/logo`,
      method: "GET",
      headers: getHeaders(),
    };
  },
  getAllShippings: (data) => {
    return {
      url: `${API_ROOT_URL}/shipments/otp`,
      method: "POST",
      headers: getHeaders(),
      data,
    };
  },

  getSlots: (data, key) => {
    return {
      url: `${API_ROOT_URL}/slots/shipment?availability=true`,
      method: "POST",
      headers: getHeaders(),
      data,
      params: { guestKey: key },
    };
  },
  getSlotsByAddress: (data, key) => {
    return {
      url: `${API_ROOT_URL}/slots/address?availability=true`,
      method: "POST",
      headers: getHeaders(),
      data,
      params: { shipmentId: key.shipmentId, guestKey: key.key },
    };
  },

  startReturn: (id, params) => {
    return {
      url: `${API_ROOT_URL}/returns/start`,
      method: "POST",
      headers: getHeaders(),
      data: {
        shipment: {
          id: id,
        },
        ...params,
      },
    };
  },

  addReturn: (data, key) => {
    return {
      url: `${API_ROOT_URL}/returns`,
      method: "POST",
      headers: getHeaders(),
      params: { guestKey: key },
      // params: { key },
      data,
    };
  },

  getParcels: (id, key) => {
    return {
      url: `${API_ROOT_URL}/shipments/${id}/parcels`,
      method: "GET",
      headers: getHeaders(),
      params: { key },
    };
  },

  createBooking: (data, key) => {
    return {
      url: `${API_ROOT_URL}/bookings`,
      method: "POST",
      headers: getHeaders(),
      params: { guestKey: key },
      data,
    };
  },

  sendMessage: (data, id) => {
    return {
      url: `${API_ROOT_URL}/communications/mail`,
      method: "POST",
      headers: getHeaders(),
      params: { shipmentId: id },
      data,
    };
  },
  requestOtp: (mobileNumber, shipmentId, optInEnabled) => {
    return {
      url: optInEnabled
        ? `${API_ROOT_URL}/opt/out/request`
        : `${API_ROOT_URL}/opt/in/request`,
      method: "POST",
      headers: getHeaders(),
      data: {
        mobileNumber,
        shipmentId,
      },
    };
  },
  requestShipmentOtp: (data) => {
    return {
      url: `${API_ROOT_URL}/otp`,
      method: "POST",
      headers: getHeaders(),
      data,
    };
  },
  enableOtp: (pin, requestId, optInEnabled) => {
    return {
      url: optInEnabled
        ? `${API_ROOT_URL}/opt/out/enable`
        : `${API_ROOT_URL}/opt/in/enable`,
      method: "POST",
      headers: getHeaders(),
      data: {
        pin,
        requestId,
      },
    };
  },
  activateNotifications: (name, number) => {
    return {
      url: `https://campaign.app.lifedata.ai/webhook/26dcae79-368d-4d13-b60c-4c021ecba090`,
      method: "POST",
      headers: {
        // ...getHeaders(),
        Authorization:
          "Bearer eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiMTUzOGI2ZGU5NjFjZDA5ZDA1MzQzYTRjZTU2Y2FiMTdiNWYyYzE1ZTJkMmExMTE3ODg3MTkzMjVlMGVhNzI2NDc4NjExZGM2MzI5NGU3MzM3YzgyMzQ0Yzc1YTA5MDk1ZTJhM2M3YzNhZDhkNTZjY2EwOGRjOTgyOWEzZjFmYmJjNzBlZWQ2OWE5Nzg3YWUyMzNhOWNhMzYwMTAwNTZhMjA1MmJjNDAzMjBhMTNkNmI2YTNhZWQ3ZDlmZjNkMDBmNzBmNzk4OTUzZGQ3YTY3MmQ1MDc3Y2ZiOGZiMjI2MjI2ODI2NDgxNDRmZjdiNDE3YTc5YjcwODU5MzVlZGFkODY1NmYwYjI5Y2JiYWNkZjE2NTM1N2YwN2JhYmYwNGE5Zjk5YmIzYzA3OTllODIyOTFhN2ZjOTA5ZmY5OGYwMTJmNzRlODEyNjM2NGY5NzYzNzIzYTYyOTcxNmQ2ZGZiZTc4OTRlNDIxOTk1ZGZmYjU5NzUyMTk3MDQ0ZDEyMDc3ZmJmM2FmMWI2YmU1ZmRlNSIsIkxJRkVfREFUQV9OQU1FX1NQQUNFIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiZW50ZXJwcmlzZV9hZG1pbiJdLCJ4LWhhc3VyYS1kZWZhdWx0LXJvbGUiOiJlbnRlcnByaXNlX2FkbWluIiwieC1oYXN1cmEtdXNlci1pZCI6IjY3MGI2MTcwLTA4YzUtNDkxZS05ODU0LWY3MmNmYWNmOThiMSIsIngtaGFzdXJhLWVudGVycHJpc2UtaWQiOiI2NzBiNjE3MC0wOGM1LTQ5MWUtOTg1NC1mNzJjZmFjZjk4YjEifSwiaWF0IjoxNjM1Nzc1MzAwLCJleHAiOjE3MjIwODg5MDB9.cfLtRkxS7NNmP3xHmZ0NmQRfYj_NdEAt8WovXB5opOdtSIEPKu5N7FM0ZxM5iRvlGvXgieAnCIRaaHizazPD5L5v6Wotuq23dt1xkj3m-c8nD29137XwHGpJ_v6ShoQP8XE3l5MH_rjMZJtFN46bt56qyZWZzSTeSOVrGkeEk2pmJwb7Ib2LkQ5rNjGHsVLQDpPZQdguZuEuSjzTmSezS_gQQUAOLf-ypVIEmVaWio2AI_fGP45CtEJpijBO0mJNO4KC7a-T60e8fnbVpNUYWXqINO3gjOGSyvkO6kPcN2onP3BTJwKgwJKwEaqDm0PwCl91ldYe21tcy9_AhB75iqSVcC1J4C7qJJfcK2gikbTccbDM-efR19WIZ0cGYnQUAiLV1pQtGflgDQtuTWHA_vSzGPeoMcS8r-ptgTCdsJnvher6Bo2ZQZcZYoeHjUa44X4rl1BxWkEP1zSCGPeTYVrWJEqt_dWJaVL5ZbknvIz0YFO6xJ3DG_952hmy243C0SyV0mwPKY5pXpyzlca4D7hhty0z89Dvh3ovYrM1T6Js9j2kQcxdbyoVrMVMq_UQEONQJRoNu3OMq405Wm7cQuA4htOx5UrHAw-B-r44ZHnNDhagmm7qm0zGVwLPt85TS1e8R54g2AZrdfsT34BVpvsoT-sZM4UbtZjHxOqc_nU",
      },
      data: {
        name: "typ_shipping_update",
        payload: {
          name: name,
        },
        to: number,
      },
    };
  },

  // STORAGE

  getAllStorages: (params) => {
    return {
      url: `${API_ROOT_URL}/warehouse-storages`,
      method: "GET",
      headers: {
        ...getHeaders(),
        Authorization: "Bearer 9QQb945N-FcAm-9c33-9uZA-yoSe3nZaMMUA",
      },
      params,
    };
  },
  getProvision: (id) => {
    return {
      url: `${API_ROOT_URL}/warehouse-storages/${id}/provision`,
      method: "GET",
      headers: {
        ...getHeaders(),
        Authorization: "Bearer 9QQb945N-FcAm-9c33-9uZA-yoSe3nZaMMUA",
      },
    };
  },

  // PUDOS

  getAllPudos: (id, key) => {
    return {
      url: `${API_ROOT_URL}/pudos/distance?guestKey=${key}`,
      method: "POST",
      headers: getHeaders(),
      data: {
        shipmentId: id,
        // maxDistance: 5,
      },
    };
  },

  sendToPudo: (id, pudoId, key) => {
    return {
      url: `${API_ROOT_URL}/shipments/to-pudo?guestKey=${key}`,
      method: "PUT",
      headers: getHeaders(),
      data: {
        shipmentId: id,
        pudoId,
      },
    };
  },

  // RATING

  getRatingOptions: (id, key, language) => {
    console.log("language!!!!!!!!!!!!!!!!", language);
    return {
      url: `${API_ROOT_URL}/rating-options?shipmentId=${id}&guestKey=${key}`,
      method: "GET",
      headers: getHeaders(language),
    };
  },
  createNewRate: (id, key, rateData) => {
    return {
      url: `${API_ROOT_URL}/ratings?shipmentId=${id}&guestKey=${key}`,
      method: "POST",
      headers: getHeaders(),
      data: rateData,
    };
  },
};

export default requests;
